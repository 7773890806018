import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import Asana from '../../assets/Integration/asana.svg';
import Aws from '../../assets/Integration/aws.svg';
import Github from '../../assets/Integration/github.svg';
import GoogleSheet from '../../assets/Integration/googleSheets.svg';
import Jira from '../../assets/Integration/jira.svg';
import Visa from '../../assets/Integration/visa.svg';
import Word from '../../assets/Integration/word.svg';
import Hamburger from '../../assets/hamburger';
import Union from '../../assets/union';
import Button from '../FormComponents/Button/Buttons';
import InteCards from './InteCards';
import './index.css';

const IntegrationTabsView = ({searchData}) => {
    const [key, setKey] = useState('All integrations');
    const [changeMode, setChangeMode] = useState(true);
    // const [cards, setcards] = useState([]);
    const [filterData, setFilterData] = useState([]);

    let tabsList = ["All integrations", "Vendors", "Finance", "Security", "Database", "Automation"]
    let defaultCard = {
        "name":"Add Custom API keys",
        "desc": "Integrate your Own API’s here.",
        "link": "How it works",
        "btnName": "Add your Custom APIs", // connect, connected, view keys
        "btnIcon": true,
    }
    let cards = [
    defaultCard,
    {
        "name":"Amazon Web Services",
        "desc": "Intergrate facebook with IDflow for seamless notification and ",
        "btnName": "Connect", // connect, connected, view keys
        "logo": Aws,
        "learnMore": "Learn more",
        "btnIcon": true,

        
    },
    {
        "name":"Google sheets",
        "desc": "Intergrate facebook with IDflow for seamless notification and ",
        "btnName": "Connected",
        "logo": GoogleSheet,
        "learnMore": "Learn more",
        "isConnected": true,
        
    },
    {
        "name":"Jira",
        "desc": "Intergrate facebook with IDflow for seamless notification and ",
        "btnName": "Connect",
        "learnMore": "Learn more",
        "logo": Jira,
        "btnIcon": true,

    },
    {
        "name":"Microsoft word",
        "desc": "Intergrate facebook with IDflow for seamless notification and ",
        "btnName": "Connect",
        "learnMore": "Learn more",
        "logo": Word,
        "btnIcon": true,

    },
    {
        "name":"Asana",
        "desc": "Intergrate facebook with IDflow for seamless notification and ",
        "learnMore": "Learn more",
        "btnName": "Connect",
        "logo":Asana
    },
    {
        "name":"Asana",
        "desc": "Intergrate facebook with IDflow for seamless notification and ",
        "btnName": "Connected",
        "learnMore": "Learn more",
        "logo": Asana,
        "isConnected": true
    },
    {
        "name":"Github",
        "desc": "Intergrate facebook with IDflow for seamless notification and ",
        "btnName": "Connected",
        "learnMore": "Learn more",
        "logo": Github,
        "isConnected": true

    },
    {
        "name":"Visa Network",
        "desc": "Intergrate facebook with IDflow for seamless notification and ",
        "btnName": "View Keys",
        "logo": Visa,
        "isConnected": false

    },
]

useEffect(() => {
    const filteredResults = cards?.filter(item => item.name.toLowerCase().includes(searchData.toLowerCase()));
    setFilterData(filteredResults);
}, [searchData, changeMode]);

  return (
    <div className="pos_fixed w-100">
      <div className="left_sec">
        <Button className="d-inline-flex" variant={key && "outlined"}  value={"My Integrations"} onClick={() => setKey("")}  />
        <Tabs
        id="fill-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="tabs-integration"
        >
            {
                tabsList?.map((tab) => (
                    <Tab eventKey={tab} title={tab}>
                        <InteCards name={tab} cards={filterData} cardClass={changeMode} />
                    </Tab>
                ))
            }
        </Tabs>
        </div>
        <div className="right_sec align-self-start mt-2">
            <div className="btn-container">
                <button className={` btn-union`} onClick={() => setChangeMode(true)}>
                    <Union />
                </button>
                <button className={` btn-burger`} onClick={() => setChangeMode(false)}>
                    <Hamburger />
                </button>
            </div>
        </div>
        </div>

    )
}

export default IntegrationTabsView