import * as Sentry from "@sentry/react";
import "bootstrap/dist/css/bootstrap.min.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ConfirmSignUp from "./components/confirm";
import ForgetPasswordEmail from "./components/forgetPasswordEmail";
import ForgetScreen from "./components/forgetScreen";
import Integration from "./components/Integrations/Integrations";
import MetaDoc from "./components/Integrations/MetaDoc";
import LoginScreen from "./components/loginScreen";
import OneTimePassword from "./components/otp";
import PasswordScreen from "./components/passwordScreen";
import Profile from './components/profile';
import ChangePassword from './components/profile/changePassword';
import Register from "./components/register/register";
import SetPassword from "./components/setPassword";
import SignUp from "./components/signUp/signup";
import { ToastContainer } from "react-toastify";
import ApiKeyManager from "./components/Integrations/apiKeyManager";
import CreateSecretKey from "./components/Integrations/CreateSecretKey";
import NotificationTemplate from './components/NotificationTemplate';
import SocialPageCall from './components/SocialLogCall';
// import AllUserDetails from "./components/AllUserDetails/index";
import {UserHome, fetchDataLoader} from "./components/AllUserDetails/UserHome";
import "./styles/global.css";
import { APIKEYMANAGER_ID, CREATESECRETKEY_ID, INTEGRATION } from './util/routes';
import AccountSettings from "./components/AccountSettings/accountSettings";
import NewUser from "./components/AllUserDetails/NewUser";
import { ErrorPage } from "@idflow/styles";
import { PageNotFound } from "@idflow/styles";
const DSN = "https://48ed957d34d342cbbbfe55ed9c4da998@o4505214857838592.ingest.sentry.io/4505584043884544";

Sentry.init({
  dsn: DSN,
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 1.0,
})

const path = (p) => "/user" + p;
const homePath = (h) => "/home" + h;
const router = createBrowserRouter([
  {
    path: homePath("/users/notifications"),
    element: <NotificationTemplate />,
    errorElement: <ErrorPage />
  },
  {
    path: homePath(INTEGRATION),
    element: <Integration />,
    errorElement: <ErrorPage />
  },
  {
    path: homePath(CREATESECRETKEY_ID + ":id"),
    element: <CreateSecretKey />,
    errorElement: <ErrorPage />
  },
  {
    path: homePath(APIKEYMANAGER_ID + ":id"),
    element: <ApiKeyManager />,
    errorElement: <ErrorPage />
  },
  {
    path: homePath("/users/secrets/Meta-doc"),
    element: <MetaDoc />,
    errorElement: <ErrorPage />
  },
  {
    path: homePath("/users/change-password"),
    element: <ChangePassword />,
    errorElement: <ErrorPage />
  },
  {
    path: homePath("/users/profile"),
    element: <Profile />,
    errorElement: <ErrorPage />
  },
  {
    path: path("/login"),
    element: <LoginScreen />,
    errorElement: <ErrorPage />
  },
  {
    path: path("/otp"),
    element: <OneTimePassword />,
    errorElement: <ErrorPage />
  },
  {
    path: path("/signup"),
    element: <SignUp />,
    errorElement: <ErrorPage />
  },
  {
    path: path("/register"),
    element: <Register />,
    errorElement: <ErrorPage />
  },
  {
    path: path("/confirm"),
    element: <ConfirmSignUp />,
    errorElement: <ErrorPage />

  },
  {
    path: path("/setpassword"),
    element: <SetPassword />,
    errorElement: <ErrorPage />

  },
  {
    path: path("/forget"),
    element: <ForgetPasswordEmail />,
    errorElement: <ErrorPage />

  },
  {
    path: path("/forgetScreen"),
    element: <ForgetScreen />,
    errorElement: <ErrorPage />

  },
  {
    path: path("/passwordscreen"),
    element: <PasswordScreen />,
    errorElement: <ErrorPage />

  },
  {
    path: path(""),
    element: <SignUp />,
    errorElement: <ErrorPage />

  },
  {
    path:homePath("/users"),
    errorElement: <ErrorPage />,

    children:[
      {
        index:true,
        element:<UserHome/>,
        loader:fetchDataLoader,

      },{
      path:"new",
        element:<NewUser/>,
        loader:fetchDataLoader,
      },{
      path:":userId/edit",
        element:<NewUser/>
      }
    ]
  },
  // {
  //   path: homePath("/users/new"),
  //   element: <NewUser />,
  // },
  {
    path: path("/workos/callback"),
    element: <SocialPageCall />,
    errorElement: <ErrorPage />

  },
  {
    path: homePath('/users/account/settings'),
    element: <AccountSettings />,
    errorElement: <ErrorPage />

  },
  {
    path: path("*"),
    element: <LoginScreen />,
    errorElement: <ErrorPage />

  },
  {
    path: "*",
    element: <PageNotFound />
  }
]);



const App = () => {
  return (
    <>
      <RouterProvider router={router} />
      <ToastContainer />
    </>

  )
};

export default App;
