import { notification } from 'antd';
import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useLocation, useNavigate } from "react-router-dom";
import { verifyOTP } from "../../api/users";
import '../../styles/global.css';
import CustomButton from "../core/Button";
import CustomFooter from "../core/Footer";
import CustomHeader from "../core/Header";
import IDFlowHeader from "../core/ID-Flow-Header/IDFlowHeader";
import CountdownTimer from "./timer";

export default function OneTimePassword() {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [loader, setLoader] = useState(false);
  const [emailWithDot, setEmailWithDot] = useState("");
  const [api, contextHolder] = notification.useNotification();

  const showErrorMessage = (err) => {
    api.error({
      description: err ? err : "Something went wrong",
      placement: "bottomRight",
      duration: 2 ,
      className: 'toast-error-message'
    })
  };
  const showSuccessMessage = (res) => {
    api.success({
      description: res ? res : "OTP Verification successful",
      placement:"bottomRight",
      duration: 2,
      className: 'toast-success-message'
    })
  };

  const location = useLocation();
  let email = location.state?.email;

  function convertEmail(email) {
    const atIndex = email.indexOf('@');
    if (atIndex > 2) {
      const firstTwoChars = email.slice(0, 2).toUpperCase();
      const asterisks = '*'.repeat(atIndex - 2);
      const domainPart = email.slice(atIndex);
      return firstTwoChars + asterisks + domainPart;
    }
    // If the email is not in the expected format, return the original email.
    return email;
  }

  useEffect(() =>{
    if(email){
      const res = convertEmail(email)
      setEmailWithDot(res)
    }
  },[email])

  const hanldeVerifyOTP = () => {
    let checkOtp = /[a-zA-Z]/.test(otp);
    if (otp.length === 6 && checkOtp === false) {
      const body = {
        email: email,
        otp: Number(otp),
      };
      setLoader(true);
      verifyOTP(body)
        .then((res) => {
          if (res) {
            showSuccessMessage();
            setTimeout(() => {
              navigate("/user/forgetScreen", {
                state: {
                  email: email,
                },
              });
            }, 2000);
          } else {
            showErrorMessage("Invalid OTP, Please enter the valid OTP");
            setOtp('')
          }
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
            showErrorMessage(err.response.data.message ? err.response.data.message :"Invalid OTP, Please enter the valid OTP")
          setOtp('')
        });
    } else {
      showErrorMessage("Enter valid OTP");
      setOtp('')
    }
  };

  return (
    <div className="main-forget">
      <IDFlowHeader/>
      <div className="body-forget">
        <div className="forget-sec">
          <div>
            <CustomHeader
              title={"Digital Onboarding Platform"}
              description={"Forgot Password"}
              subDescription={
                `Please Enter the OTP received to your mail ID ${emailWithDot} to change the Password.`
              }
              isforgetStyle={"forget-form-heading"}
            />

            <div className="otp-dev">
              <p className="field-title-login">Enter OTP</p>
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderInput={(props) => <input {...props} />}
                placeholder="______"
                isInputSecure
                containerStyle={{ gap: "17px" }}
                className="id_otp_input"
                inputStyle={{
                  border: "1px solid  #C6CACE",
                  width: "40px",
                  height: "40px",
                  padding: "8",
                  borderRadius: 4,
                }}
              />
            </div>
            <p className="timer">
              <CountdownTimer textMesg={"Resend OTP in"} showSuccessMessage={showSuccessMessage} showErrorMessage={showErrorMessage} setOtp={setOtp}/>
            </p>
          </div>
          <CustomButton title={"Verify OTP"} handleFunction={hanldeVerifyOTP} loader={loader} />
        </div>
      </div>
      {contextHolder}
      <CustomFooter />
    </div>
  );
}
