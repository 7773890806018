import React, { useRef } from "react";

import {Link, Outlet} from "react-router-dom";
import { useState, useEffect } from "react"; // Add this import for state management
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PlusIcon from "../../assets/plusIconLight.svg";
import EditIcon from "../../assets/userScreenSvg/edit-icon.svg";
import MySvg from "../../assets/userScreenSvg/save-icon.svg";
import UserDetail from "./userDetail";
import "./index.css";
import CustomButton from "../core/Button";
import BackArrowIcon from "../../assets/userScreenSvg/back-arrow.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import { baseURLImage } from "../../api/common";
import { ToastContainer } from "react-toastify";
import SearchSortFilter from "../SearchFilterSort";
import {getAllUsers, getUser} from "../../api/users";
import {getAllWorkFlow} from "../../api/workflow";
import {getAllGroups} from "../../api/groups";
import {getAllAccounts} from "../../api/accounts";
import {getAllSecrets} from "../../api/secretManager";
import {getAllDomains} from "../../api/domain";
import {getAllNodes} from "../../api/nodes";



export const UserHome = () =>{
    const scrollSectionRef = useRef(null);

    const [data, setData] = useState([]); // Getting all users data and stored in state.
    const [selectedId, setSelectedId] = useState(null); // Add state management for 'selected'

    const fetchData = async () => {
        try {
            const res = await getAllUsers();
            setData(res);
            console.log("alll users data",res)
            setSelectedId(res[0].id);
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    const handleUserDelete = (userId) => {
        const updatedData = data.filter(user => user.id !== userId);
        setData(updatedData);

        const updateSelectedId = () => {
            const deletedUserIndex = data.findIndex(user => user.id === userId);
            if (deletedUserIndex !== -1) {
                const nextUserIndex = (deletedUserIndex + 1) % data.length;
                setSelectedId(data[nextUserIndex].id);
            } else {
                setSelectedId(null);
            }
        };

        updateSelectedId();
    };

    const handleSelect = (id) => {
        setSelectedId(id);
    };
    return (
        <>
            <Container fluid className="mt-2">
                <div className="">
                    <ToastContainer />
                    <div className="d-flex justify-content-between align-content-center">
                        <div className="builder-main-heading">
                            <Link to={"users"} className="TextDecorationNone">
                            <p className="users-title">Team members</p>
                            </Link>
                            <p className="users-title-desc">
                                View team's current members, make necessary edits to their details
                                if required.
                            </p>
                        </div>
                        <Link to={"new"} className="TextDecorationNone">
                            <button className="builder-heading-btn"><img src={PlusIcon} alt="Idflow"/> Create User</button>
                            {/*<CustomButton title={""}  />*/}
                        </Link>
                    </div>
                    {/*UserDetails Screen*/}
                    <div className="mb-25">
                        <SearchSortFilter width={"31.1%"} />
                    </div>
                </div>
                <Row className="">
                    <Col lg="3" className="scroll-sec">
                        {data &&
                            data?.map((value, index) => (
                                <Container
                                    fluid
                                    className="p-0 custom_hover d-flex flex-wrap ps-0 gx-10 cursor-pointer align-items-center mb-10"
                                    style={
                                        selectedId === value.id
                                            ? {
                                                height: '78px',
                                                borderRadius: '8px',
                                                border: '1px solid #DDE2E5',
                                                background:'#fff',
                                                boxShadow: '0px 0px 1px 0px #091E4226, 0px 18px 28px 0px #091E420D',
                                            }
                                            : {}
                                    }

                                    onClick={() => handleSelect(value.id)}
                                    key={value.id}
                                >
                                    <Col
                                        lg="3"
                                        xxl="2"
                                        className="col1 ps-2 img-fluid"
                                        style={{
                                            // backgroundColor: selected.id === value.id ? "white" : "",
                                            // borderTop:
                                            //     selected.id === value.id ? "1.5px solid #DDE2E5" : "",
                                            // borderLeft:
                                            //     selected.id === value.id ? "1.5px solid #DDE2E5" : "",
                                        }}
                                    >
                                        {value?.image ? (
                                            <img
                                                src={baseURLImage + value.image}
                                                alt={"icon"}
                                                height="45rem"
                                                width="45rem"
                                                className="rounded-img"
                                            />
                                        ) : (
                                            <p className="mb-0 SplitUserTitle">
                                                {value.full_name
                                                    ? value.full_name.split(' ').map((word, index) =>
                                                        index < 2 ? word[0] : ''
                                                    ).join('')
                                                    : 'NN'}
                                                {/*{value.full_name*/}
                                                {/*    ? value.full_name.split(' ').length >= 2*/}
                                                {/*        ? value.full_name.split(' ')[0][0] + value.full_name.split(' ')[1][0]*/}
                                                {/*        : value.full_name[0]*/}
                                                {/*    : 'Invalid input'}*/}
                                            </p>
                                        )}
                                    </Col>
                                    <Col
                                        lg="5"
                                        xxl="6"
                                        className="col2 p-0 m-0 pt-3 pb-3"
                                        style={{
                                            // backgroundColor: selected.id === value.id ? "white" : "",
                                            // borderTop:
                                            //     selected.id === value.id ? "1.5px solid #DDE2E5" : "",
                                        }}
                                    >
                                        <p className="p-0 m-0 ml-4 spanText">{value.full_name}</p>
                                        <p
                                            className="p-0 m-0 ml-4 font_style"
                                            style={{ fontSize: "14px", fontWeight: "400" }}
                                        >
                                            {value?.phone_number?.country_code}{" "}
                                            {value?.phone_number?.number}
                                        </p>
                                    </Col>
                                    <Col
                                        lg="4"
                                        className="col2 p-0 m-0 pt-3 pb-3 d-flex justify-content-center"
                                        style={{
                                            // backgroundColor: selected.id === value.id ? "white" : "",
                                            // borderTop:
                                            //     selected.id === value.id ? "1.5px solid #DDE2E5" : "",
                                            // borderRight:
                                            //     selected.id === value.id ? "1.5px solid #DDE2E5" : "",
                                        }}
                                    >
                                        <div
                                            className="custom-styles "
                                            style={{
                                                // backgroundColor: selected.id === value.id ? "white" : "",
                                                border:
                                                    value.is_admin === true
                                                        ? "1px solid #DDE2E5"
                                                        : value.is_admin === false
                                                            ? "1px solid #DDE2E5"
                                                            : "",
                                            }}
                                        >
                                            {value.is_admin ? (
                                                "Admin"
                                            ) : value.is_admin === false ? (
                                                "User"
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </Col>
                                </Container>
                            ))}
                    </Col>

                    <Col lg="9" className="border-6">
                        <div className="custom-container">
                            <div className="d-flex main-div p-3 ">
                                <div className="d-flex align-items-center ">
                                    {/*<div>*/}
                                    {/*  <img*/}
                                    {/*      src={BackArrowIcon}*/}
                                    {/*      alt="back arrow"*/}
                                    {/*      className="m-0 cursor-pointer"*/}
                                    {/*      onClick={() => setAddNewUser(false)}*/}
                                    {/*  />*/}
                                    {/*</div>*/}
                                    <p className="textSize ps-2">User Details</p>
                                </div>
                            </div>
                            <UserDetail selectedId={selectedId} onUserDelete={handleUserDelete} />
                        </div>
                    </Col>
                </Row>
                {/*  UserDetails Screen*/}
            </Container>
        </>
    )
}

export const fetchDataLoader = async () => {
    try {
        const [workflowData, groupsData, accountsData, secretsData, domainsData,nodesData,userData] = await Promise.all([
            getAllWorkFlow('name'),
            getAllGroups('name'),
            getAllAccounts('name'),
            getAllSecrets('name'),
            getAllDomains(),
            getAllNodes('name'),
            getUser()
        ]);
        return { workflowData, groupsData, accountsData, secretsData, domainsData, nodesData, userData };
    } catch (error) {
        throw error;
    }
};
