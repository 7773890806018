import { commonRequest } from "./common";

const customDomainPath = (domainId) => "/domains/" + domainId;


// Creating Custom Domain
export const addCustomDomain = (body) => {
    return commonRequest("post", customDomainPath(""), body)
};

// Get Custom Domains
export const getCustomDomains = () => {
    return commonRequest("get", customDomainPath(""))
}

// Delete Custom Domain
export const deleteCustomDomain = (domainId) => {
    return commonRequest("delete", customDomainPath(domainId), domainId)
}