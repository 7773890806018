import React from 'react';
import { Select,Space } from 'antd';
import './index.css'
const CustomSelect = (props) => {
 const {options,className,mode,style,placeholder,
    defaultValue,onChange,optionLabel,id,disabled,label,labelfor,onSearch} = props
    return (
  <React.Fragment>
    <div className='select_wrapper'>
      <label for={labelfor} className="labelClass">{label}</label>
      <Select
        {...props}
        mode={mode}   // single or multiple
        className={`id_flow_select ${className}`}
        style={style}
        id={id}
        placeholder={placeholder}
        disabled={disabled}
        defaultValue={defaultValue}
        onChange={onChange}
        onSearch={onSearch}
        optionLabelProp={optionLabel}
        maxTagCount="responsive"  //responsive
      >
        {options?.map((item,index) => {
          return (
            <Option value={item.value} id={index} label={item.label}>
              <Space>
                {item.value}
              </Space>
          </Option>
          )
        })}
      </Select>
    </div>
  </React.Fragment>
)};
export default CustomSelect;


