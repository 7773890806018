import React, { useState, useEffect } from 'react'
import deleteIcon from '../../assets/delete.svg';
import './accountSettings.css'
import SelectDropdown from '../Selectdropdown/selectDropdown'
import Modal from "react-bootstrap/Modal";

import { addCustomDomain, getCustomDomains, deleteCustomDomain } from '../../api/customDomain';

const getDomain = () =>{
    let host = window.location.hostname.split(".");
    return host.at(-2) + "." + host.at(-1)
 }

const AccountSettings = () => {

    const [domainModalShow, setDomainModalShow] = useState(false);
    const [deleteDomainModalShow, setDeleteDomainModalShow] = useState(false);
    const [domain, setDomain] = useState();
    const [validDomain, setValidDomain] = useState("");
    const [customDomainList, setCustomDomainList] = useState([]);
    const [domainToDelete, setDomainToDelete] = useState(null);


    const closeModal = () => {
        setDomainModalShow(false);
        setDomain("");
        setValidDomain("");
    }
    const showAddDomainModal = () => {
        setDomainModalShow(true);
    }


    const handleDomainChange = (event) => {
        setDomain(event.target.value);
        validDomain && domain?.length === 1 ? setValidDomain("") : ""
    };

    // Add custom domain
    const handleAddCustomDomain = () => {
        const domainRegex = /[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+/;
        if (!domainRegex.test(domain)) {
            setValidDomain("Invalid Domain Format.")
            return;
        } else {
            addCustomDomain({ domain: domain })
                .then(res => {
                    setDomain("");
                    setDomainModalShow(false);
                    getAllCustomDomains();
                })
                .catch(error => console.error(error));
        }

    };

    // Get Custom Domain
    const getAllCustomDomains = async () => {
        try {
            const res = await getCustomDomains();
            if (res && res.length > 0) {
                setCustomDomainList(res);
            }
        } catch (err) {
            console.log(err, "error");
        }
    };

    // Delete Custom Domain
    const deleteCustomDomains = (domainId) => {
        deleteCustomDomain(domainId)
            .then(() => {
                const updatedCustomDomainList = customDomainList.filter((cusDomain) => cusDomain.id !== domainId);
                setCustomDomainList(updatedCustomDomainList);
                setDeleteDomainModalShow(false);
            })
            .catch((error) => {
                console.error('Error deleting custom domain:', error);
            });
    };

    useEffect(() => {
        getAllCustomDomains();
    }, []);

    const openDeleteModal = (domain) => {
        setDomainToDelete(domain);
        setDeleteDomainModalShow(true);
    };

    return (
        <>
            <div className='heading-acc-settings mb-4 pb-4'>
                <div class="left_sec">
                    <div class="node-builder-heading">
                        <div class="node-builder-main-heading">
                            <h1>Account Settings</h1>
                            <p class="sub_text">Apply your unique business branding to maintain a familiar and recognizable experience for your users.</p>
                        </div>
                    </div>
                </div>
                <div>
                    <button className="dsButton btn-ripple btn">Save Changes</button>
                </div>
            </div>
            <section className='br-1px'>
                <div>
                    <div class="acc-card-head">
                        <h1>Organizational Details</h1>
                        <p>Please choose the language for the web portal.</p>
                    </div>
                </div>
                <div className="acc_selection">
                    <div className="selection_wrapper">
                        <SelectDropdown label="Organization Name" onChange={(e) => { console.log(e.value); }} />
                    </div>
                    <div className="selection_wrapper">
                        <SelectDropdown label="Default Currency" />
                    </div>

                </div>
            </section>


            <section className='br-1px'>
                <div class="acc-card-head mb-2">
                    <h1>Domain Settings</h1>
                    <p>Use your own domain or opt for a customized domain from ID Flow.</p>
                </div>
                <div className='domain___settings___label'>
                    <h5 >
                        Default Subdomain
                    </h5>
                    <div className='row'>
                        <div className='col-lg-2 domain__name dn-label'>
                            <p>Your domain name</p>
                        </div>
                        <div className='col-lg-7 domain__name dn-domain'>
                            <p>{window.location.hostname}</p>
                        </div>
                    </div>
                    <div className="acc-card-head">
                        <h1>Custom Domain</h1>
                        <h5 className='pt-1 mb-0'>Your Custom URL's</h5>
                        <p>Add a new record with <span>Type CName</span> and <span>Value</span>&nbsp; {`proxy.${getDomain()}`}</p>
                    </div>
                    <table className='custom-domain-table'>
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Custom Domain</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {customDomainList &&
                                customDomainList.map((cusDomains, index) => {
                                    const activeDomain = cusDomains.active === true;
                                    return (
                                        <tr key={cusDomains.id}>
                                            <th>{index + 1}</th>
                                            <td>{cusDomains.domain}</td>
                                            <td className={activeDomain ? "status_green" : "status_red"}>
                                                {activeDomain ? "Active" : "Inactive"}
                                            </td>
                                            <td>
                                                <img
                                                    src={deleteIcon}
                                                    onClick={() => openDeleteModal(cusDomains)}
                                                    alt="delete icon"
                                                />
                                            </td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                    <button className='add-custom-domain-btn' onClick={showAddDomainModal}>Add Custom Domains</button>
                </div>
            </section >

            <section className='br-1px'>
                <div class="acc-card-head mb-2">
                    <h1>Email White Labeling </h1>
                    <p>Opt for either the default email option or use your custom email to communicate with your customers.</p>
                </div>
                <div>
                    <div className='idflow__acc__settings__radio__main mt-4'>
                        <div className="idflow___acc__settings__radio">
                            <input id="radio-1" name="radio" type="radio" checked />
                            <label for="radio-1" className="radio-label">Default</label>
                            <p><span>The default subdomain is </span> {`no-reply.${getDomain()}`}</p>
                        </div>
                        <div className="idflow___acc__settings__radio">
                            <input id="radio-2" name="radio" type="radio" />
                            <label for="radio-2" className="radio-label">Custom email white labeling</label>
                            <p>Send email notifications from your preferred email address rather than using the <span>{`no-reply.${getDomain()}`}</span></p>
                        </div>
                    </div>
                    <div>
                        <label id="email" className='radio-label mb-2'>Email</label><br />
                        <input type='email' placeholder='Enter your email address' className='acc__settings__email' />
                    </div>
                </div>
            </section>

            {/* Modal */}

            <Modal Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={domainModalShow}
                onHide={closeModal}
                className="add_custom_domain_modal"
            >
                <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 domain_modal_body">
                                <h3 className="cd-main-head">Add Custom Domain</h3>
                                <h6>Domain Address</h6>
                                <input
                                    placeholder='www.mywebsite.com'
                                    type='url'
                                    className='cd-modal-inp'
                                    name="cusDomain"
                                    value={domain}
                                    onChange={handleDomainChange}
                                />
                                {
                                    <p className='domainInvalid mb-0'>{validDomain}</p>
                                }
                                <div className='cd-modal-btns'>
                                    <button className='add-custom-domain-btn add-custom-domain-btn-modal' onClick={handleAddCustomDomain}>
                                        Add Custom Domains
                                    </button>
                                    <button className='add-custom-domain-btn add-custom-domain-btn-cancel' onClick={closeModal}>
                                        Cancel
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* Delete Modal */}

            <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={deleteDomainModalShow}
                onHide={() => setDeleteDomainModalShow(false)}
                className="add_custom_domain_modal"
            >
                 <div className="modal-main">
          <h3>Are you sure?</h3>
          <p className="mb-0">You can't able to retrieve. <br/>Do you want to delete {domainToDelete?.domain} ?</p>
          <div className="modal-btns-div">
            <button onClick={() => deleteCustomDomains(domainToDelete?.id)}>Yes, Delete</button>
            <button onClick={() => setDeleteDomainModalShow(false)}>No, Cancel</button>
          </div>
        </div>
            </Modal>

        </>
    )
}

export default AccountSettings 