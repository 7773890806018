import React from 'react'

const Union = () => {
  return (
<svg
  xmlns="http://www.w3.org/2000/svg"
  width={18}
  height={18}
  viewBox="0 0 18 18"
  fill="none"
>
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M1.60001 1.86667C1.60001 1.71938 1.71941 1.6 1.86667 1.6H6.13333C6.28059 1.6 6.39999 1.71938 6.39999 1.86667V6.13333C6.39999 6.28062 6.28059 6.4 6.13333 6.4H1.86667C1.71941 6.4 1.60001 6.28062 1.60001 6.13333V1.86667ZM1.86667 0C0.835729 0 0 0.835743 0 1.86667V6.13333C0 7.16426 0.835729 8 1.86667 8H6.13333C7.16427 8 8 7.16426 8 6.13333V1.86667C8 0.835743 7.16427 0 6.13333 0H1.86667ZM11.6 1.86667C11.6 1.71938 11.7194 1.6 11.8667 1.6H16.1333C16.2806 1.6 16.4 1.71938 16.4 1.86667V6.13333C16.4 6.28062 16.2806 6.4 16.1333 6.4H11.8667C11.7194 6.4 11.6 6.28062 11.6 6.13333V1.86667ZM11.8667 0C10.8357 0 10 0.835743 10 1.86667V6.13333C10 7.16426 10.8357 8 11.8667 8H16.1333C17.1643 8 18 7.16426 18 6.13333V1.86667C18 0.835743 17.1643 0 16.1333 0H11.8667ZM1.86667 11.6C1.71941 11.6 1.60001 11.7194 1.60001 11.8667V16.1333C1.60001 16.2806 1.71941 16.4 1.86667 16.4H6.13333C6.28059 16.4 6.39999 16.2806 6.39999 16.1333V11.8667C6.39999 11.7194 6.28059 11.6 6.13333 11.6H1.86667ZM0 11.8667C0 10.8357 0.835729 10 1.86667 10H6.13333C7.16427 10 8 10.8357 8 11.8667V16.1333C8 17.1643 7.16427 18 6.13333 18H1.86667C0.835729 18 0 17.1643 0 16.1333V11.8667ZM11.6 11.8667C11.6 11.7194 11.7194 11.6 11.8667 11.6H16.1333C16.2806 11.6 16.4 11.7194 16.4 11.8667V16.1333C16.4 16.2806 16.2806 16.4 16.1333 16.4H11.8667C11.7194 16.4 11.6 16.2806 11.6 16.1333V11.8667ZM11.8667 10C10.8357 10 10 10.8357 10 11.8667V16.1333C10 17.1643 10.8357 18 11.8667 18H16.1333C17.1643 18 18 17.1643 18 16.1333V11.8667C18 10.8357 17.1643 10 16.1333 10H11.8667Z"
    fill="white"
  />
</svg>
  )
}

export default Union