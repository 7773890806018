import React, { useState } from "react";
import Logo from "../../assets/idflow-logo.svg";
import { sendResetEmail } from "../../api/users";
import { useNavigate } from "react-router-dom";
import "../loginScreen/login.css";
import { setEmail as setEmailToLocalStorage } from "../../api/common";
import { toast } from "react-toastify";
import CustomHeader from "../core/Header";
import CustomInput from "../core/InputField";
import CustomButton from "../core/Button";
import CustomFooter from "../core/Footer";
import { notification } from 'antd'
import '../../styles/global.css';

export default function ForgetPasswordEmail() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [loader, setLoader] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  const showErrorMessage = (err) => {
    api.error({
      description: err ? err : "Some thing went wrong",
      placement: 'bottomRight',
      duration: 2,
      className: 'toast-error-message'
    })
  };
  const showSuccessMessage = (res) => {
    api.success({
      description: res ? res : "OTP sent successfully to email",
      placement: 'bottomRight',
      duration: 2,
      className: 'toast-success-message'
    })
  };
  const handleSendResetEmail = () => {
    const domainRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$/i;
    if (email) {
      setLoader(true)
      if (domainRegex.test(email)) {
        sendResetEmail(email)
          .then((res) => {
            if (res) {
              showSuccessMessage();
              setEmailToLocalStorage(email);
              setTimeout(() => {
                navigate("/user/otp", {
                  state: {
                    email: email,
                  },
                });
              }, 2000);
            } else {
              showErrorMessage("User not exist");
            }
            setLoader(false)
          })
          .catch((err) => {
            setLoader(false)
            showErrorMessage("Entered email ID is not registered. Please enter the registered email ID");
          });
      } else {
        setLoader(false)
        showErrorMessage("Please enter a valid email id");
      }
    } else {
      showErrorMessage("Please enter email");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSendResetEmail();
    }
  };
  return (
    <div className="main-forget">
      <img src={Logo} alt="logo" />
      <div className="body-forget">
        <div className="forget-sec">
          <div>
            <CustomHeader
              title={"Digital Onboarding Platform"}
              description={"Forgot Password"}
              subDescription={
                "Please Enter the Email ID to proceed , we will send you the OTP to you."
              }
              isforgetStyle={"forget-form-heading"}
            />
            <div className="form_group_input">
              <CustomInput
                label={"Email ID"}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                handleKeyPress={handleKeyPress}
              />
            </div>
          </div>
          <CustomButton
            title={"Send Request"}
            handleFunction={handleSendResetEmail}
            loader={loader}
          />
        </div>
      </div>
      {contextHolder}
      <CustomFooter />
    </div>
  );
}
