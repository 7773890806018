import { globalCommonRequest, globalCommonRequestFile } from '@idflow/styles';

import { BASE_URL as base_url } from "@idflow/styles";
import axios from 'axios';
import { redirect } from "react-router-dom";


export const BASE_URL = base_url();

const ACCESS_TOKEN_KEY = "accessToken";
const EMAIL = "email";
const USERDATA = "userdata";


export function accessToken() {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
}

export function setAccessToken(token) {
  localStorage.setItem(ACCESS_TOKEN_KEY, token);
}

export function setEmail(email) {
  localStorage.setItem(EMAIL, email);
}
export function setUserData(user) {
  localStorage.setItem(USERDATA, JSON.stringify(user));
}
export function getUserData() {
  const userObject = localStorage.getItem(USERDATA);
  return JSON.parse(userObject);
}

export function getEmail() {
  return localStorage.getItem(EMAIL);
}
export function clearAccessToken(token) {
  localStorage.clear();
}

export function universalError(err) {
  const status_code = err.response.status;
  if (status_code === 401) {
    return redirect("/login");
  }
}

export const METHODS = {
  get: "get",
  post: "post",
  put: "put",
  delete: "delete",
};


// export const request = async (method, v, path, body) => {
//   try {
//     const response = await axios({
//       url: BASE_URL + v + path,
//       method: method,
//       headers: {
//         Authorization: "Bearer " + accessToken(),
//       },
//       data: body,
//     });

//     return response.data;
//   } catch (err) {
//     throw err;
//   }
// };
// export const requestFile = async (method, v, path, body) => {
//   try {
//     const response = await axios({
//       url: BASE_URL + v + path,
//       method: method,
//       headers: {
//         Authorization: "Bearer " + accessToken(),
//         "Content-Type": "multipart/form-data",
//       },
//       data: body,
//     });

//     return response.data;
//   } catch (err) {
//     throw err;
//   }
// };
export const requestLogin = async (method, v, path, body) => {
  const response = await axios({
    url: BASE_URL + v + path,
    method: method,
    data: body,
  });

  return response.data;
};

// export const commonRequest = async (method, path, body) => {
//   return await request(method, "v1", path, body);
// };

// export const commonRequestV1 = (method, path, body, onSuccess, onFailure) => {
//   return request(method, "v1", path, body, onSuccess, onFailure);
// };
// export const commonRequestV1File = (method, path, body, onSuccess, onFailure) => {
//   return requestFile(method, "v1", path, body, onSuccess, onFailure);
// };
// export const LoginRequestV1 = (method, path, body) => {
  
//   return requestLogin(method, "v1", path, body);
// };

// export const socialLoginReqV1 = (method,path,body)=>{
//   return requestLogin(method, "v1", path, body);
// }


export const commonRequest = async (method, path, body) => {
    return await globalCommonRequest(method, path, body);
};

export const commonRequestV1 = async (method, path, body, onSuccess, onFailure) => {
    return await globalCommonRequest(method, path, body, onSuccess, onFailure);
};
export const commonRequestV1File = async (method, path, body, onSuccess, onFailure) => {
    return await globalCommonRequestFile(method, path, body, onSuccess, onFailure);
};
export const LoginRequestV1 = async (method, path, body) => {
    return await requestLogin(method, "v1", path, body);
};

export const socialLoginReqV1 = async (method,path,body)=>{
    return await requestLogin(method,"v1", path, body);
}

export const baseURLImage = `${base_url()}v1/files/`;
