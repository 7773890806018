import React from 'react';
import Button from '../FormComponents/Button/Buttons';
import CustomHeader from '../core/Header';

const NewUserRegistration = ({email="dynamic@gmail.com", setnewUserRegState, createAccount}) => {

    const handleBack = () => { 
        setnewUserRegState(1);
    };

  return (
    <div className='new-user-register'>
      <CustomHeader
        title={"Welcome to ID Flow"}
        />
        <p>
            {`Hey,We have seen that your mail Id is not register yet please do register on IDflow with ${email}`} <span className="edit-btn" onClick={handleBack}>Edit</span>
        </p>
        <div className="register-btn-w-100">
            <Button value="Send Verfication Link" onClick={() => createAccount()} />
        </div>

    </div>
    )
}

export default NewUserRegistration;