import React, { useState } from "react";
import Logo from "../../assets/idflow-logo.svg";

import { useNavigate } from "react-router-dom";
import "../loginScreen/login.css";
import { resetPassword as ResetPassword } from "../../api/users";
import { getEmail, setEmail } from "../../api/common";
import { toast } from "react-toastify";
import CustomHeader from "../core/Header";
import CustomInput from "../core/InputField";
import CustomFooter from "../core/Footer";
import CustomButton from "../core/Button";
import { ForgetMsg } from "../../util/helper";
import IDFlowHeader from "../core/ID-Flow-Header/IDFlowHeader";
import {notification} from 'antd'
import '../../styles/global.css';

export default function ForgetScreen() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const emailaddress = getEmail();
  const [validPassword,setValidPassword]=useState(false)
  const [api, contextHolder] = notification.useNotification();
  const [resetPassword, setResetPassword] = useState({
    password: "",
    newPassword: "",
  });
  const showErrorMessage = (err) => {
    api.error({
      description: err ? err : "Some thing went wrong",
      placement: 'bottomRight',
      duration: 2,
      className:'toast-error-message'
    })
  };
  const showSuccessMessage = (res) => {
    api.success({
      placement:"bottomRight",
      description: res ? res : "New password set Successfully",
      duration: 2,
      className: 'toast-success-message'
    })
  };
  const validatePassword = (password) => {
    if (password.length < 8) {
      return false;
    }

    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
    if (!specialCharRegex.test(password)) {
      return false;
    }

    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    if (!uppercaseRegex.test(password) || !lowercaseRegex.test(password)) {
      return false;
    }
    const numberRegex = /\d/;
    if (!numberRegex.test(password)) {
      return false;
    }

    return true;
  };
  const handleReset = () => {
    const value=validatePassword(resetPassword.password);
    setValidPassword(value)
    if (resetPassword.password === "" && resetPassword.newPassword === "") {
      showErrorMessage("Please enter password");
    } else if (value){ if (resetPassword.password === resetPassword.newPassword) {
      const body = {
        email: emailaddress,
        password: resetPassword.password,
      };
      ResetPassword(body)
        .then((res) => {
          if (res) {
            showSuccessMessage()
            setEmail(null);
            setTimeout(()=>{
              navigate("/user/login");
            },2000)

          }else{
            showErrorMessage("User not found")
          }
        })
        .catch((error) => {
          if (error.response) {
            const statusCode = error?.response?.status;
            if (statusCode === 400) {
              const errorMessage = error?.response?.data?.message;
              showErrorMessage(errorMessage);
            } else {
              showErrorMessage("An error occurred during the API call");
            }
          } else {
            // The error was not from the API response (e.g., network error)
            console.error("Network Error:", error?.message);
            showErrorMessage(
              "Network Error: Unable to connect to the server"
            );
          }
        });
    } else {
      showErrorMessage("Password not matched");
    }
  }else{
    showErrorMessage(<ForgetMsg/>)
  }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleReset()
    }
  };

  return (
    <div className="main-login">
      <IDFlowHeader/>
      <div className="body-login">
        <div className="login-sec">
          <div>
            <CustomHeader
              title={"Digital Onboarding Platform"}
              description={"Welcome back!"}
              subDescription={"Please create new password"}
            />
            <div className="form_group_input">
              <CustomInput
                label={"Create new password"}
                onChange={(e) =>
                  setResetPassword((prev) => ({
                    ...prev,
                    password: e.target.value,
                  }))
                }
                placeholder="∗∗∗∗∗"
                type={showPassword ? "text" : "password"}
                setShowPassword={() => setShowPassword(!showPassword)}
                isIcon
                handleKeyPress={handleKeyPress}
              />
            </div>
            <div className="form_group_input">
              <CustomInput
                label={"Confirm password"}
                onChange={(e) =>
                  setResetPassword((prev) => ({
                    ...prev,
                    newPassword: e.target.value,
                  }))
                }
                placeholder="∗∗∗∗∗"
                type={showPassword1 ? "text" : "password"}
                setShowPassword={() => setShowPassword1(!showPassword1)}
                isIcon
                handleKeyPress={handleKeyPress}
              />
            </div>
          </div>
          <CustomButton
            title={"Change Password"}
            handleFunction={handleReset}
          />
        </div>
      </div>
      {contextHolder}
      <CustomFooter />
    </div>
  );
}
