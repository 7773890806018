import React from 'react'

const Hamburger = () => {
  return (
<svg
  xmlns="http://www.w3.org/2000/svg"
  width={24}
  height={24}
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M4 5.88889C4 5.39797 4.37052 5 4.82759 5H19.1724C19.6295 5 20 5.39797 20 5.88889C20 6.37981 19.6295 6.77778 19.1724 6.77778H4.82759C4.37052 6.77778 4 6.37981 4 5.88889ZM4 12C4 11.5091 4.37052 11.1111 4.82759 11.1111H19.1724C19.6295 11.1111 20 11.5091 20 12C20 12.4909 19.6295 12.8889 19.1724 12.8889H4.82759C4.37052 12.8889 4 12.4909 4 12ZM4 18.1111C4 17.6202 4.37052 17.2222 4.82759 17.2222H19.1724C19.6295 17.2222 20 17.6202 20 18.1111C20 18.602 19.6295 19 19.1724 19H4.82759C4.37052 19 4 18.602 4 18.1111Z"
    fill="#0F172A"
  />
</svg>
  )
}

export default Hamburger