import React from 'react';
import Button from '../FormComponents/Button/Buttons';

const TextBlog = ({heading, paragraph, button, handleButton, buttletPoints}) => {
  return (
        <>
            <h1>{heading}</h1>
            <p>{paragraph}</p>
            {button?.length > 0 && <Button value={button} onClick={() => handleButton}/>}
            {(buttletPoints && Array.isArray(buttletPoints)) && (
                buttletPoints.map((item, i) => (
                    <ul key={i} className='m-0'>
                        <li>{item}</li>
                    </ul>
                )) 
            )}
        </>
    )
}

export default TextBlog;