import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deleteSecrets, getAllSecrets } from '../../api/secretManager';
import AlertDialog from '../../assets/Integration/alert-dialog.svg';
import CopyIcon from '../../assets/copy.svg';
import DeleteIcon from '../../assets/delete.svg';
import EditIcon from '../../assets/edit.svg';
import { copyToClipboard } from '../../util/helper';
import { CREATESECRETKEY_ID, INTEGRATION } from '../../util/routes';
import Buttons from '../FormComponents/Button/Buttons';
import IntegrationHeader from '../HeadingInfoPanel';
import SearchBarInput from './SearchBar';

const ApiKeyManager = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const routeName = location.pathname;
    const isCustom = routeName.includes("custom");
    const [tableData, setTableData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [itemId, setItemId] = useState(0);
    let createSecretKey =  CREATESECRETKEY_ID;

    useEffect(() => {
        fetchUsers()
    }, []);
    
    
    const fetchUsers = async () => { 
        try {
            let resp = await getAllSecrets()
            setTableData(resp);
        } catch (error) {
            console.log(error, 'err')
        }
    }
  
    // const textAreaRef = useRef(null);
    // let LiRefs = []
    const showErrorMessage = (err) => {
        toast.error(err ? err : "Some thing went wrong", {
          position: toast.POSITION.TOP_RIGHT,
        });
    };
    const showSuccessMessage = (msg) => {
    toast.success(msg, {
        position: toast.POSITION.TOP_RIGHT,
    });
    };
    
    const handleCopy = (textToCopy) => {
      copyToClipboard(textToCopy, showErrorMessage, showSuccessMessage);
    };


    const handleEdit = (id) => { 
        navigate(`/home${createSecretKey+id}`)
     }

     const handleDelete = async (id) => { 
        setShowModal(false)
        try {
            await deleteSecrets(id)
            toast.success("Key Deleted Successfully")
        } catch (error) {
            console.log(error, 'del error')
        }

       fetchUsers()
      }


      const getDate = (value) => { 
        let date = new Date(parseInt(value, 10))
        return date.toLocaleDateString()
       }

  return (
        <>
            <IntegrationHeader 
                heading={"Meta Integration"}
                description={"Create and save your secret API Keys."}
                isBreadCrumb={"integration"}
                isBreadCrumbLink={INTEGRATION}
                ButtonName={"Add New key"}
                btnNavigation={`${isCustom ? createSecretKey+"custom" : createSecretKey  }`}
            />
            <div className="box-container d-inline-flex g-5 w-100 column px-4" style={{ minHeight: '70vh'}}>
                <SearchBarInput
                className="w-50 mt-4"
                placeholder={"Search"}
                onChange={(e) => console.log(e.target.value)}
                // value={handleSearch}
                />
                <div className="cus-form m-0 mw-100 mt-3">
                    <div className="block-box">
                    <div className="table_mat_sec">
                        <table>
                            <thead>
                                <tr>
                                    <th className='cus-borderRight'>Name</th>
                                    <th className='cus-borderRight'>Key</th>
                                    {isCustom && <th className='cus-borderRight'>Action</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    Array.isArray(tableData) && tableData?.map((item, i) => {
                                        return (
                                        <tr key={i}>
                                            <td className='p-3'>{item.name}</td>
                                            <td className='p-3'>
                                                {item?.id}
                                                <button className="copy-btn float-end" onClick={() => handleCopy(item?.id)}>
                                                    <img src={CopyIcon} alt="icon" />
                                                </button>
                                            </td>
                                            <td className='p-3 d-flex justify-content-between align-items-center'>
                                                <p className='m-0 text-gray'>Last Updated: {getDate(item?.lastUpdated)}</p>
                                                <div className='float-end d-flex gap-3'>
                                                <button className="copy-btn" onClick={() => handleEdit(item?.id)}>
                                                    <img src={EditIcon} alt="icon" />
                                                </button>
                                                <button className="copy-btn" onClick={() => {
                                                    setShowModal(true)
                                                    setItemId(item?.id)
                                                }}>
                                                    <img src={DeleteIcon} alt="icon" />
                                                </button>
                                                </div>
                                            </td>
                                        </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        </div>
                    </div>
                </div>
            </div>


            <Modal
                // size="fullscreen"
                dialogClassName="modal-100w"
                aria-labelledby="example-custom-modal-styling-title"
                centered
                show={showModal}
                onHide={() => setShowModal(false)}
                // className="select_workflows_modal"
            >
                <Modal.Body>
                <div className="">
                    <div className="row">
                        <div className="col-lg-6 content_wrapper d-flex align-items-center">
                            <img src={AlertDialog} alt="" width={"221.21px"} height={"222px"} />
                        </div>
                        <div className="col-lg-6 d-flex flex-column justify-content-center">
                            <div className="attention">
                                <h1>Attention!</h1>
                                <p>This action cannot be undone. This will permanently delete the workflow runs, as they effect the workflow and remove all collaborator associations.</p>
                            </div>
                            <div className="d-flex gap-3">
                                <Buttons value="Delete" variant="" onClick={() => handleDelete(itemId)} />
                                <Buttons variant="outlined" value="Cancel" onClick={() => setShowModal(false)} />
                            </div>
                        </div>
                    </div>
                </div>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default ApiKeyManager
