import { notification } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Profile_pic from '../../assets/profile_pic.svg';
import Button from '../FormComponents/Button/Buttons';
import CountrySelect from '../FormComponents/CountryPicker/CountrySelect';
import Inputs from '../FormComponents/Input/Inputs';
import PhoneSelect from '../FormComponents/TelSelect/TelSelect';
import "./index.css";
import { getUser, sendEmail, updateUser } from '../../api/users';
import { checkFormValidation } from './validation';

const Profile = () => {
  const [isDisable, setIsDisable] = useState(true);
  const [userDetails, setUserDetails] = useState('');
  const [editDetails, setEditDetails] = useState('');
  const [splitFullName, setSplitFullName] = useState('');
  const [fullName, setFullName] = useState({
    fname: '',
    lname: ''
  })
  const [profileText, setProfileText] = useState("");
  const [updateNumber, setUpdateNumber] = useState(userDetails);

  const [loader, setLoader] = useState(false);

  // toaster
  const [api, contextHolder] = notification.useNotification();

  // toaster 
  useEffect(() => {
    get_user_details();
  }, []);

  const get_user_details = async () => {
    await getUser().then(res => {
      setUserDetails(res);

      let splitName = res?.full_name?.split(' ');
      let splitFirstName = splitName?.slice(0, splitName.length - 1);
      let splitLastName = splitName[splitName.length - 1]
      setSplitFullName([splitFirstName.join(' '), splitLastName]);
      // text for profile Image
      if (!splitName) {
        setProfileText("ID")
      } else if (splitName?.length === 1) {
        setProfileText(splitName[0].charAt(0).toUpperCase())
      } else {
        const profileFName = splitFirstName[0].charAt(0).toUpperCase();
        const profileLName = splitLastName[0].charAt(0).toUpperCase();
        setProfileText(profileFName + profileLName)
      }
      setEditDetails(res);
      setUpdateNumber(res?.phone_number);
    }).catch(err => {
      console.log(err)
    })
  }

  useEffect(() => {
    setFullName({ fname: splitFullName[0], lname: splitFullName[1] })
  }, [splitFullName])


  const handleEdit = () => {
    setIsDisable(false);
  };

  const navigate = useNavigate();

  //function for cancel button
  const handleCancel = () => {
    setFullName({ fname: splitFullName[0], lname: splitFullName[1] })
    setEditDetails(userDetails);
    setUpdateNumber(userDetails?.phone_number)
    setIsDisable(true);
  }

  //Function for Save button 
  const handleSave = async () => {
    setLoader(true);
    let value = checkFormValidation({ editDetails, showErrorMessage, fullName, updateNumber });
    if (value === false) {
      const full_name = fullName?.fname + ' ' + fullName?.lname;

      let body = {
        organization: editDetails?.organization,
        organization_name: editDetails?.organization_name,
        full_name: full_name,
        phone_number: updateNumber,
        service: editDetails?.service,
        email: editDetails?.email,
      }
      if (userDetails?.email !== editDetails?.email) {
        // sendEmail(editDetails?.email).then(res => console.log(res)).catch(err => console.log(err));
        try {
          await sendEmail(editDetails?.email);
          // await update_details(full_name, body);
          openNotification('Verification email sent successfully');
        } catch (err) {
          console.log('Error sending email:', err);
          return;
        }
      }
      else {
        await update_details(full_name, body);
        openNotification('Profile updated successfully ');
      }
    }
    setLoader(false);
    setIsDisable(true);
  }

  const update_details = async (full_name, body) => {
    setEditDetails({ ...editDetails, full_name: full_name, phone_number: updateNumber });
    let data = await updateUser(userDetails.id, body);
    await get_user_details();
  }

  //  get Selected Country onChange     
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  //Function for Enter key press
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSave();
    }
  }

  //Phone number field
  const handlePhoneNumber = (phone, country) => {
    let number = parseInt(phone.slice(country.dialCode.length))
    setUpdateNumber({ country_code: parseInt(country.dialCode), number: number, })
  }

  //Error Toast
  const showErrorMessage = (err) => {
    api.error({
      description: err ? err : "Please fill all the fields",
      placement: "bottomRight",
      duration: 2,
      className: 'toast-error-message'
    })
  };
  //Success Toast
  const openNotification = (msg) => {
    api.success({
      description: msg,
      placement: 'bottomRight',
      duration: 2,
      className: 'toast-success-message'
    });
  };
  // Open Toaster 
  return (
    <section className='profile-main'>
      <h1 className='profile-heading'>My Profile</h1>
      <div className="row box">
        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 m-0 pl-0">
          <div className='imgFlex'>
            <div className='profile-textImg'>
              <h4 className='mb-0'>{profileText}</h4>
            </div>
            <div className='profile-title'>
              <h2>{userDetails.full_name} </h2>
              <span>Admin</span>
            </div>
          </div>
        </div>
        <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12 m-0'>
          <div className='ml-auto'>
            <Button
              value="Change Password"
              variant="contained"
              onClick={() => navigate("/home/users/change-password")}
            />
          </div>
        </div>

      </div>
      <div className='cus-form'>
        <div className='block-box custom-block-box'>
          <div className='custom_label'>
            <Inputs
              label="First Name"
              htmlFor="firstName"
              type="text"
              id="firstName"
              placeholder='First name'
              disabled={isDisable}
              value={fullName.fname}
              handleKeyPress={handleKeyPress}
              onChange={e => setFullName({ ...fullName, fname: e.target.value })}
            />
          </div>
          <div className='custom_label'>
            <Inputs
              label="Last Name"
              htmlFor="lastName"
              type="text"
              id="lastName"
              placeholder='Last name'
              disabled={isDisable}
              value={fullName.lname}
              handleKeyPress={handleKeyPress}
              onChange={e => setFullName({ ...fullName, lname: e.target.value })}
            />
          </div>
        </div>
        <div className='block-box'>
          <PhoneSelect
            label="Phone"
            htmlFor="phone"
            id="phone"
            disabled={isDisable}
            value={`${updateNumber?.country_code} ${updateNumber?.number}`}
            onChange={handlePhoneNumber}
          />
        </div>
        <div className='block-box'>
          <CountrySelect
            label="Country"
            id="country"
            defaultValue={['India']}
            onChange={handleChange}
            disabled={isDisable}
          />
        </div>
        <div className='block-box'>
          <Inputs
            label="Email"
            htmlFor="email"
            type="email"
            id="email"
            placeholder='stephen@gmail.com'
            disabled={isDisable}
            value={editDetails?.email}
            onChange={e => setEditDetails({ ...editDetails, email: e.target.value })}
            handleKeyPress={handleKeyPress}
          />
        </div>

        <div className='block-box'>
          <Inputs
            label="Organisation"
            htmlFor="organisation"
            type="text"
            id="organisation"
            placeholder='Syntizen'
            disabled={isDisable}
            value={editDetails?.organization}
            onChange={e => setEditDetails({ ...editDetails, organization: e.target.value })}
            handleKeyPress={handleKeyPress}
          />
        </div>

      </div>
      {/* toaster */}
      <div>{contextHolder}</div>
      {/* toaster */}
      <div className='buttonSection'>
        {isDisable ? <Button value="Edit" onClick={handleEdit}
          imgLeft={<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M4.276 10.5951L11.0373 3.83377L10.0947 2.89111L3.33333 9.65244V10.5951H4.276ZM4.82867 11.9284H2V9.09977L9.62333 1.47644C9.74835 1.35146 9.91789 1.28125 10.0947 1.28125C10.2714 1.28125 10.441 1.35146 10.566 1.47644L12.452 3.36244C12.577 3.48746 12.6472 3.657 12.6472 3.83377C12.6472 4.01055 12.577 4.18009 12.452 4.30511L4.82867 11.9284ZM2 13.2618H14V14.5951H2V13.2618Z" fill="white" /></svg>} />
          :
          <div className='saveEditButton'>
            <Button value="Save" onClick={handleSave}
              loader={loader}
              imgLeft={<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99968 2.26617C4.83153 2.26617 2.26324 4.83446 2.26324 8.0026C2.26324 11.1708 4.83153 13.739 7.99968 13.739C11.1678 13.739 13.7361 11.1708 13.7361 8.0026C13.7361 4.83446 11.1678 2.26617 7.99968 2.26617ZM1.33301 8.0026C1.33301 4.32071 4.31778 1.33594 7.99968 1.33594C11.6816 1.33594 14.6663 4.32071 14.6663 8.0026C14.6663 11.6845 11.6816 14.6693 7.99968 14.6693C4.31778 14.6693 1.33301 11.6845 1.33301 8.0026ZM11.4293 5.81325C11.611 5.99489 11.611 6.28939 11.4293 6.47103L7.16577 10.7346C6.98413 10.9162 6.68964 10.9162 6.508 10.7346L4.57001 8.79661C4.38837 8.61497 4.38837 8.32047 4.57001 8.13883C4.75165 7.9572 5.04615 7.9572 5.22779 8.13883L6.83688 9.74793L10.7716 5.81325C10.9532 5.63161 11.2477 5.63161 11.4293 5.81325Z" fill="white" />
              </svg>}
            />
            <Button variant="outlined" imgLeft={<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99968 2.26617C4.83153 2.26617 2.26324 4.83446 2.26324 8.0026C2.26324 11.1708 4.83153 13.739 7.99968 13.739C11.1678 13.739 13.7361 11.1708 13.7361 8.0026C13.7361 4.83446 11.1678 2.26617 7.99968 2.26617ZM1.33301 8.0026C1.33301 4.32071 4.31778 1.33594 7.99968 1.33594C11.6816 1.33594 14.6663 4.32071 14.6663 8.0026C14.6663 11.6845 11.6816 14.6693 7.99968 14.6693C4.31778 14.6693 1.33301 11.6845 1.33301 8.0026ZM5.81032 5.81325C5.99196 5.63161 6.28646 5.63161 6.4681 5.81325L7.99968 7.34483L9.53125 5.81325C9.71289 5.63161 10.0074 5.63161 10.189 5.81325C10.3707 5.99489 10.3707 6.28939 10.189 6.47103L8.65745 8.0026L10.189 9.53418C10.3707 9.71582 10.3707 10.0103 10.189 10.192C10.0074 10.3736 9.71289 10.3736 9.53125 10.192L7.99968 8.66038L6.4681 10.192C6.28646 10.3736 5.99196 10.3736 5.81032 10.192C5.62868 10.0103 5.62868 9.71582 5.81032 9.53418L7.3419 8.0026L5.81032 6.47103C5.62868 6.28939 5.62868 5.99489 5.81032 5.81325Z" fill="#3D4551" />
            </svg>} onClick={handleCancel} value="Cancel" />
          </div>}
      </div>
    </section>
  )
}

export default Profile