import React from "react";

const CustomHeader = (props) => {
  const {
    title,
    description,
    subDescription,
    isforgetStyle,
    descpSpan,
    paraClass
  } = props
  return (
    <div>
      <p className="title-login">{title}</p>
      {
        description && (
      <p
        className={
          subDescription && isforgetStyle
            ? isforgetStyle
            : subDescription
            ? "login-form-desc1"
            : "login-form-desc"
        }
      >
        {description}
      </p>
      )}
      {subDescription && (
      <p className={`${isforgetStyle ? "forget-form-desc" : "login-form-desc2"} ${paraClass && paraClass}`}>
        {subDescription}
        {descpSpan && props.children}
      </p>
      )}
    </div>
  );
};

export default CustomHeader;
