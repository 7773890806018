import { notification } from 'antd';
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { checkUserRegister, createOrg, fetchTenant } from "../../api/users";
import GreenTick from '../../assets/userScreenSvg/greenTick.svg';
import WrongIcon from '../../assets/userScreenSvg/remove-icon.svg';
import '../../styles/global.css';
import CustomButton from "../core/Button";
import CustomCheckBox from "../core/CustomCheckBox";
import CustomSelect from "../core/CustomSelect";
import CustomFooter from "../core/Footer";
import CustomHeader from "../core/Header";
import IDFlowHeader from "../core/ID-Flow-Header/IDFlowHeader";
import CustomInput from "../core/InputField";
import "../loginScreen/login.css";
import "./index.css";

export default function Register() {
  const navigate = useNavigate();
  const [emailAddress, setEmailAddress] = useState("");
  const [loader,setLoader] = useState(false);
  const [username, setUsername] = useState({
    firstName: "",
    lastName: "",
  });
  const [validTenant, setValidTenant] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [isChecked, setIsChecked] = useState(false);
  const regex = /[a-zA-Z]+$/;
  const serviceOption = [
    {
      label:"Bank",
      value:"Bank"
    },
    {
      label:"Fintech",
      value:"Fintech"
    },
    {
      label:"NBFC",
      value:"NBFC"
    },
    {
      label:"Insurance",
      value:"Insurance"
    },
    {
      label:"Education",
      value:"Education"
    },
    {
      label:"Others",
      value:"Others"
    }
  ];  
  const location = useLocation();
  
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const email = searchParams.get("e");
    setUsername({...username, firstName:searchParams.get("firstName") || "", lastName:searchParams.get("lastName") || ""})
    try {
      const decode = window?.atob(email);
      setEmailAddress(decode);
    } catch (error) {
      
    }
    const token = searchParams.get("Id");
    if (email && token) {
      const body = {
        email,
        token,
      };
      checkUserRegister(body)
      .then((res) => {
        if (res && res.message === "verification successful") {
          // showSuccessMessage(res?.message);
        } else {
          showErrorMessage();
          setTimeout(() => {
            navigate("/user/signup");
          }, 2000);
        }
      })
      .catch((err) => {
        showErrorMessage(err?.response?.data?.message);
        setTimeout(() => {
          navigate("/user/signup");
        }, 2000);
      });
    }
  }, []);

  useEffect(() => {
    if (validTenant) {
      formik.setFieldValue('tenant', formik.values?.tenant, true)
    }
  }, [validTenant]);
  
  const showErrorMessage = (err) => {
    api.error({
      description: err ? err : "Email not verified",
      placement: "bottomRight",
      duration: 2,
      className: 'toast-error-message'
    })
  };

  const showSuccessMessage = (success) => {
    api.info({
      description: success ? success : "Email verification Successful",
      placement:"bottomRight",
      duration: 2,
      className: 'toast-success-message'
    })
  };
  

  useEffect(() => {
    formik.setFieldValue("full_name", username.firstName + " " + username.lastName, false)
    formik.setFieldValue("email", emailAddress, true)

  }, [username, emailAddress]);


  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      formik.handleSubmit();
    }
  };

  let filteredValue = serviceOption?.map(item => item.value)

  const validation = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    name: yup.string().required(),
    domain: yup.string().matches(/^(?!-)[A-Za-z0-9-]{1,63}(?<!-)(\.[A-Za-z]{2,})+$/,'Invalid domain format').required('Domain is required'),
    tenant: yup.string().test(
      'tenant',
      ({value}) => `${value ? value + " not available" : "Tenant is Required"}`,
      () => validTenant
    ).matches(/^[A-Za-z_][A-Za-z0-9_]{3,}$/,'Invalid tenant format').required('Tenant is required'),
    service: yup 
    .mixed()
    .oneOf(filteredValue).required(),
   })

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      name: "",
      domain: "",
      tenant: "",
      service: ""
    },
    validationSchema:  validation,
    onSubmit: (values, helpers) => {
      values["full_name"] = values.firstName + " " + values.lastName
      delete values.firstName;
      delete values.lastName;
      if (isChecked) {
          setLoader(true);
          createOrg(values)
          .then((res) => {
            setLoader(false);
            helpers.resetForm();
            setIsChecked(false);
            if (res) {
              navigate(`/user/passwordscreen/?Id=${res.Id}&e=${res.email}`)
              showSuccessMessage(
                "User created successfully"
              );              
            }
          })
          .catch((err) => {
            setLoader(false);
            showErrorMessage(err?.message);
          });
        } else {
          setLoader(false);
          showErrorMessage("Please Agree to the Terms and Conditions");
        }
    },
    
  });

  const handleTenant = (event) => { 
    const {id, value} = event.target;
    formik.setFieldValue(id, value, true)
  };

  const tenantApiCall =  async () => { 
    try {
      if (formik.values?.tenant) {
        let res = await fetchTenant(formik.values?.tenant);
        return res;
      }
    } catch (error) {
      console.log(error, '-->')
    }

  }

  useEffect(() => {
    let timeoutId = setTimeout(() => {
      let resp = tenantApiCall();
      resp.then((res) => {
        setValidTenant(res?.available)
      }).catch(err => {
        toast.error(err.message)
      })
    }, 500);
  
    return () => {
      clearTimeout(timeoutId);
    }
  }, [formik.values?.tenant]);

  return (
    <div className="main">
      <div className="main-signupdetails">
        <IDFlowHeader/>
        <div className="body-signupdetails">
          <div className="form-sec">
            <div>
              <CustomHeader
                title={"Digital Onboarding Platform"}
                description={
                  "We require a few more details from you to get started."
                }
              />
              <div className="field-div">
                <div className="form_group_input">
                  <CustomInput
                    label={"First Name"}
                    id={"firstName"}
                    onChange={formik.handleChange}
                    value={formik.values?.firstName}
                    onBlur={formik.handleBlur}
                    placeholder="Enter first name"
                    labelClass="field-titl"
                    alphaOnly="true"
                    handleKeyPress={handleKeyPress}
                  />
                  {(formik.errors.firstName && formik.touched.firstName) && <p className='formik_error mb-2'>{formik.errors.firstName}</p>}
                </div>
                <div className="form_group_input">
                  <CustomInput
                    label={"Last Name"}
                    id="lastName"
                    onChange={formik.handleChange}
                    value={formik.values?.lastName}
                    placeholder="Enter last name"
                    labelClass="field-titl"
                    alphaOnly="true"
                    onBlur={formik.handleBlur}
                    handleKeyPress={handleKeyPress}
                  />
                  {(formik.errors.lastName && formik.touched.lastName) && <p className='formik_error mb-2'>{formik.errors.lastName}</p>}
                </div>
              </div>             
              <div className="form_group_input">
                <CustomInput
                  label={"Organization Name"}
                  id={"name"}
                  onChange={formik.handleChange}
                  value={formik.values?.name}
                  placeholder="Enter your organization name"
                  handleKeyPress={handleKeyPress}
                  onBlur={formik.handleBlur}

                />
                  {(formik.errors.name && formik.touched.name) && <p className='formik_error mb-2'>{formik.errors.name}</p>}
              </div>
              <div className="form_group_input">
                  <CustomInput
                    label={"Domain"}
                    id={"domain"}
                    onChange={formik.handleChange}
                    values={formik.values?.domain}
                    onBlur={formik.handleBlur}
                    placeholder="Enter the domain"
                    labelClass="field-titl"
                    handleKeyPress={handleKeyPress}
                  />
                  {(formik.errors.domain && formik.touched.domain) && <p className='formik_error mb-2'>{formik.errors.domain}</p>}
                </div>
                <div className="form_group_input position-relative">
                  <CustomInput
                    label={"Tenant"}
                    id={"tenant"}
                    onChange={handleTenant}
                    value={formik.values?.tenant}
                    placeholder="Enter the tenant"
                    labelClass="field-titl"
                    onBlur={formik.handleBlur}
                    handleKeyPress={handleKeyPress}
                  />
                  {
                    formik.values?.tenant?.length > 0 && <img 
                    src={validTenant ? GreenTick : WrongIcon}
                    className={validTenant ? "tenant-icon" : "tenant-wrong"}
                    alt="" />
                  }
                  
                  {(formik.errors.tenant && formik.touched.tenant) && <p className='formik_error mb-2'>{formik.errors.tenant}</p>}
                </div>

              <div className="form_group_input">
                <CustomSelect
                  label={"How would like to use our services? "}
                  placeholder="Select service"
                  id={"service"}
                  onChange={(e) => formik.setFieldValue('service', e, true)} // setvalue
                  options={serviceOption}
                  onBlur={formik.handleBlur}
                  handleKeyPress={handleKeyPress}
                />
                  {(formik.errors.service && formik.touched.service) && <p className='formik_error mb-2'>{formik.errors.service}</p>}
              </div>
              <div className="form_group_input">
                <CustomCheckBox
                  name="check"
                  label={
                    <div>I agree to the <span className="register-span">Terms and Conditions</span></div>
                  }
                  onChange={(e)=>{
                    setIsChecked(!isChecked);
                  }}
                  handleKeyPress={handleKeyPress}
                />
              </div>
            </div>
            <div>
              <CustomButton type={"submit"} title={"Sign Up"} handleFunction={formik.handleSubmit} loader={loader} />
            </div>
          </div>
        </div>
      </div>
      {contextHolder}
      <CustomFooter />
    </div>
  );
}
