import React, { useState } from 'react';
import IntegrationHeader from '../HeadingInfoPanel';
import IntegrationTabsView from './IntegrationTabsView';

const Integrations = () => {
  const [handleSearch, sethandleSearch] = useState("");

  return (
    <div className="main_div">
      <div className=" body_component">
        <IntegrationHeader
          heading={"Integrations"}
          description={"Browse all the our available product integrations over here"}
          SearchBar={true}
          ButtonName={'Create Integration Key'}
          isBreadCrumb={false}
          sethandleSearch={sethandleSearch}
        />
        {/* tab options */}
        <IntegrationTabsView 
        
          searchData={handleSearch}
        />


      </div>
    </div>
  )
}

export default Integrations