import { notification } from "antd";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from 'yup';
import { setEmail } from "../../api/common";
import { sendEmail } from "../../api/users";
import "../../styles/global.css";
import CustomButton from "../core/Button";
import CustomFooter from "../core/Footer";
import CustomHeader from "../core/Header";
import IDFlowHeader from "../core/ID-Flow-Header/IDFlowHeader";
import CustomInput from "../core/InputField";

export default function SignUp() {
  const navigate = useNavigate();
  const [userEmail, setUserEmail] = useState("");
  const [loader, setLoader] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  const showErrorMessage = (err) => {
    api.error({
      description: err ? err : "Some thing went wrong",
      placement: "bottomRight",
      duration: 2,
      className: "toast-error-message",
    });
  };
  const showSuccessMessage = () => {
    api.success({
      description: "Email sent Successfully!.Please check your email.",
      placement: "bottomRight",
      duration: 2,
      className: "toast-success-message",
    });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      formik.handleSubmit();
    }
  };

  const validation = yup.object().shape({
    email: yup.string().email().required("Please Enter your Email").matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,}$/, "Please Check your Email"),
   })

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema:  validation,
    onSubmit: values => {
    setLoader(true);
      setEmail(values.email);
      sendEmail(values.email)
        .then((res) => {
          if (res) {
            showSuccessMessage();
          } else {
            showErrorMessage();
          }
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          showErrorMessage(err.message);
        });
    },
  });
 

  return (
    <div className="main-signup">
      {contextHolder}
      <IDFlowHeader />
      <div className="body-signup">
        <div className="signup-sec">
          <div>
            <CustomHeader
              title={"Sign up for free trial"}
              subDescription={
                "In just a few steps, you can start building custom workflows!"
              }
            />
            <CustomInput
            inputStyle={"mb-2 input-field"}
              label={"Email ID"}
              id={"email"}
              placeholder="Enter your email"
              handleKeyPress={handleKeyPress}
              onChange={formik.handleChange}
              value={formik.values.email}
              
            />
            {formik.errors.email && <p className='formik_error mb-2'>{formik.errors.email}</p>}
          </div>
          <div>
            <CustomButton
              handleFunction={formik.handleSubmit}
              title={"Sign Up"}
              loader={loader}
            />
            <p className="btn-desc">
              Do you have an account?{" "}
              <span onClick={() => navigate("/user/login")}>Sign in</span>
            </p>
          </div>
        </div>
      </div>
      <CustomFooter />
    </div>
  );
}
