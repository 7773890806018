import React, {useEffect, useRef, useState} from "react";
import {Link, useLoaderData} from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import ChatBtn from "../../assets/userScreenSvg/chat-btn.svg";
import EmailBtn from "../../assets/userScreenSvg/email-btn.svg";
import CopyBtn from "../../assets/userScreenSvg/copy-btn.svg";
import Delete from "../../assets/delete.svg";
import EditLight from "../../assets/edit-light.svg";
import Col from "react-bootstrap/Col";
import { copyToClipboard } from "../../util/helper";
import { baseURLImage } from "../../api/common";
import CustomSelect from "../core/CustomSelect";
import RmvIcon from "../../assets/userScreenSvg/remove-icon.svg";
import {deleteUser, getUser, getUserByID} from "../../api/users";
import {isUndefined} from "webpack-merge/dist/utils";
import {getAllWorkFlow} from "../../api/workflow";
import {getAllGroups} from "../../api/groups";
import {getAllAccounts} from "../../api/accounts";
import {getAllSecrets} from "../../api/secretManager";
import {getAllDomains} from "../../api/domain";
import {getAllNodes} from "../../api/nodes";

const fetchAllData = async () => {
  try {
    const [workflowData, groupsData, accountsData, secretsData, domainsData,nodesData,userData] = await Promise.all([
      getAllWorkFlow('name'),
      getAllGroups('name'),
      getAllAccounts('name'),
      getAllSecrets('name'),
      getAllDomains(),
      getAllNodes('name'),
      getUser()
    ]);
    return { workflowData, groupsData, accountsData, secretsData, domainsData, nodesData, userData };
  } catch (error) {
    throw error;
  }
};

export default function UserDetail({
                                     selectedId, onUserDelete
                                   }) {
  const [data, setData] = useState([]);
  const [record, setRecord] = useState({
    workflowData: [],
    groupsData: [],
    accountsData: [],
    secretsData: [],
    domainsData: [],
    nodesData: [],
    userData: []
  });
  const [deleteModal, setDeleteModal] = useState(false)
  const [activeBtn, setActiveBtn] = useState("Secrets");
  const [list, setList] = useState([
    {
      id: "1",
      name: "Secrets",
      isActive: true,
    },  {
      id: "2",
      name: "Domains",
      isActive: false,
    },{
      id: "3",
      name: "WorkFlows",
      isActive: false,
    },{
      id: "4",
      name: "Nodes",
      isActive: false,
    },
    {
      id: "5",
      name: "Accounts",
      isActive: false,
    },
    {
      id: "6",
      name: "Groups",
      isActive: false,
    },
    {
      id: "7",
      name: "Others",
      isActive: false,
    },
  ]);
  // Check if data permissions access exists, if not, set  to an empty array.
  const domainsAccess = data?.permissions?.domains?.access || [];
  const workflowsAccess = data?.permissions?.workflows?.access || [];
  const secretsAccess = data?.permissions?.secrets?.access || [];
  const nodesAccess = data?.permissions?.nodes?.access || [];
  const accountsAccess = data?.permissions?.accounts?.access || [];
  const groupsAccess = data?.permissions?.groups?.access || [];
  // Extract the "others" object from your data
  const others = data.permissions && data.permissions.others ? data.permissions.others : {};
  //Ends Here Check if data permissions access exists
  // Filter keys with boolean values that are true Starts
  const trueKeys = (others && typeof others === 'object')
      ? Object.entries(others)
          .filter(([key, value]) => value === true)
          .map(([key]) => key)
      : [];


  // const { fetchallData } = useLoaderData();


  // Filter keys with boolean values that are true Ends

  const fetchData = async () => {
    try {
      const res = await getUserByID(selectedId);
      // const res = await getUserByID("73A_VP1eU4ocFAtl8LwgL");
      setData(res);

    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    fetchData();
  },[selectedId] );

  useEffect(() => {
    fetchAllData()
        .then((result) => {
          setRecord(result);
          console.log("records all in user details",result)
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });


  }, []);
  const DeleteUser =  async ( id) =>{

    try {
      const res = await deleteUser(id);
      toast.success("User Deleted Successfully!" ,{ autoClose: 1000 });
      setDeleteModal(false);
      onUserDelete(id);
    } catch (err) {
      toast.error("Something Went Wrong!" ,{ autoClose: 1000 });
      setDeleteModal(false)
    }

  }

  const handleColor = (value) => {
    value.isActive = true;
    setActiveBtn(value.name);
    const update = [...list];
    update.map((val, ind) => {
      if (val.id === value.id) {
        val.isActive = true;
      } else {
        val.isActive = false;
      }
    });
    setList(update);

  };
  const handleCopy = (e) => {
    const inputValue =  document.getElementById('GetEmail');
    const textToCopy =inputValue.value;
    // Create a textarea element to copy the text
    const textArea = document.createElement('textarea');
    textArea.value = textToCopy;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);

    // Show a toast message
    toast.success('Mail Copied!', { autoClose: 1000 });
  };
  return (
    <>
      <div>
        <div className="d-flex flex-row justify-content-between sec2 align-items-center">
          <div className="UserDetailsTop">
            {data?.image ? (
                <img
                    src={baseURLImage + data.image}
                    alt={"icon"}
                    height="45rem"
                    width="45rem"
                    className="rounded-img"
                />
            ) : (
                <p className="mb-0 SplitUserDetailTitle">
                  {data.full_name
                      ? data.full_name.split(' ').map((word, index) =>
                          index < 2 ? word[0] : ''
                      ).join('')
                      : 'NN'}
                 
                </p>
            )}
            {/*<img*/}
            {/*  src={baseURLImage+data.image}*/}
            {/*  alt={data.heading}*/}
            {/*  height="45rem"*/}
            {/*  width="45rem"*/}
            {/*  className="rounded-img"*/}
            {/*/>*/}
            <div>
            <h3 className="UserDetailsSpanText m-0">{data.full_name ? data.full_name : "Name Not Available"}</h3>
            <div className="d-flex flex-row">
              <p className="UserDetailsData">
                {data?.phone_number?.country_code}{" "}
                {data?.phone_number?.number}
              </p>

            </div>

            </div>
            {data.is_admin ? (
                <div className="role-div">Admin</div>
            ) : (
                <div className="role-div">User</div>
            )}
          </div>
          <div className="UserDetailsDeleteGroup">
            <button className="Delete" onClick={() => setDeleteModal(true)}><img src={Delete} alt="idflow"/>&nbsp;&nbsp;Delete</button>
            <Link to={`${data.id}/edit`} className="TextDecorationNone"><button className="Edit"><img src={EditLight} alt="idflow"/>&nbsp;&nbsp;Edit</button></Link>
           {/*<button className="Edit"><img src={EditLight} alt="idflow"/>&nbsp;&nbsp;Edit</button>*/}
            {/*<img src={ChatBtn} alt="" />*/}
          </div>
        </div>
      </div>



      <div className="UserDetailsFull">
        <table>
          <tr>
            <th className="w-25 UserDetailsTh">Name</th>
            <td className="font_style_desc">{data.full_name ? data.full_name : "Name Not Available"}</td>
          </tr>

          <tr>
            <th className="w-25 UserDetailsTh">Phone</th>
            <td className="font_style_desc">
              {" "}
              {/*{data?.phone_number?.country_code}*/}
              {data?.phone_number?.number ? (
                <> {data.phone_number.number}</>
              ) : (
                  <>No number</>
              )}

            </td>
          </tr>
          <tr>
            <th className="w-25 UserDetailsTh">Type</th>
            <td className="font_style_desc">
              {data.is_admin ? (
                "Admin"
              ) : data.is_admin === false ? (
                "User"
              ) : (
                <></>
              )}
            </td>
          </tr>
          <tr>
            <th className="w-25 UserDetailsTh">Email</th>
            <td className="p-0 font_style_desc">
              <div className="d-flex flex-row justify-content-between email-field">
                <div className="d-flex flex-row justify-content-center align-items-center">
                  <input type="email" value={data.email} readOnly id="GetEmail"/>
                </div>

                <div className="d-flex flex-row justify-content-center align-items-center spacing-2">
                  <button className="border-0 UserDetailsCopyBtn" onClick={handleCopy}>
                    <img src={CopyBtn} alt="icon" />
                  </button>

                  {/*<button className="border-0">*/}
                  {/*  <img src={EmailBtn} alt="icon" />*/}
                  {/*</button>*/}
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th className="w-25 UserDetailsTh">Status</th>
            <td className="font_style_desc">
              {data.disabled ? "Active" : data.disabled === false ? "Deactivate" : null}
            </td>
          </tr>
        </table>
      </div>
      {/*Account and Admin Status Section Starts Here*/}
      <div className="bottom-sec-edit">
        <div className="bottom-sec-div">
          <p className="bottom-heading">Account status</p>
          <div className="d-flex flex-row bottom-div1">
            <div>
              <label className="switch">
                <input
                    checked={data.disabled}
                    type="checkbox"
                />
                <span className="slider round"></span>
              </label>
            </div>
            <div>
              <p className="bottom-role-title">Active</p>
              <p className="bottom-desc">Account is currently active.</p>

            </div>
          </div>
        </div>
        <div className="bottom-sec-div">
          <p className="bottom-heading">Admin Access</p>
          <div className="d-flex flex-row bottom-div1 ">
            <div>
              <label className="switch">
                <input
                    type="checkbox"
                    checked={data.is_admin}
                />
                <span className="slider round"></span>
              </label>
            </div>

            <div>
              <p className="bottom-role-title">Admin</p>
              <p className="bottom-desc">Account is currently an admin.</p>
            </div>
          </div>
        </div>
      </div>
      {/*Account and Admin status sections ends here*/}
      {/*Permissions section starts here*/}
      {data.is_admin ? <></> : (data.is_admin === false ? <div>
        <div className="UserDetailsBottom">
          <h4 className='UserDetailsAccess'>Access</h4>
          <div className=" bottom-div-edit">
            <div className="button-bar">
              {list.map((value, index) => {
                return (
                    <button
                        key={value.id}
                        className="bar-btn"
                        onClick={() => handleColor(value)}
                        style={{
                          backgroundColor: value.isActive ? "#212429" : "#ffffff",
                          color: value.isActive ? "#dde2e5 " : "black",
                        }}
                    >
                      {value.name}
                    </button>
                );
              })}
            </div>
            {activeBtn === "Others" ? (
                <>
                  <div className="checkbox-container">
                    {trueKeys.map((key) => (
                        <div key={key} className="checkbox-item CursorNotallowed">
                          <input
                              type="checkbox"
                              checked={true}
                          />
                          <label htmlFor={key} className="CursorNotallowed">
                            {key}
                          </label>
                        </div>
                    ))}

                  </div>
                </>

            ) : (
                <>
                  <div className="">
                    {activeBtn === "WorkFlows" ? (
                        <>
                          <div className="check-box-edit">
                          <label className="radio-button-label CursorNotallowed">
                            <input
                                type="radio"
                                value={data?.permissions?.workflows?.type === "ReadOnly" ? "Read" : "ReadAndWrite"}
                                checked={true}
                            />
                            <span className="radio-button-custom"></span>
                            {data?.permissions?.workflows?.type === "ReadOnly" ? "Read" : "Read and Write"}
                          </label>
                          </div>
                          <div className="item-btn-div">
                          {Array.isArray(workflowsAccess) ? (
                              workflowsAccess.map((access, index) => {
                                const matchingWorkflow = record.workflowData.find((workflow) => workflow.id === access);
                                const workflowName = matchingWorkflow ? matchingWorkflow.name : 'No Name';

                                return (
                                    <button className="item-btn CursorNotallowed" key={index}>
                                      <img className="remove-icon" src={RmvIcon} alt="icon" />
                                      {workflowName}
                                    </button>
                                );
                              })
                          ) : (
                             <></>
                          )}
                          </div>
                        </>
                    ) : activeBtn === "Accounts" ? (
                        <>
                          <div className="item-btn-div">
                            <label className="radio-button-label CursorNotallowed">
                              <input
                                  type="radio"
                                  value={data?.permissions?.accounts?.type === "ReadOnly" ? "Read" : "ReadAndWrite"}
                                  checked={true}
                              />
                              <span className="radio-button-custom"></span>
                              {data?.permissions?.accounts?.type === "ReadOnly" ? "Read" : "Read and Write"}
                            </label>
                          </div>
                          <div className="item-btn-div">
                            {Array.isArray(accountsAccess) ? (
                                accountsAccess.map((access, index) => {
                                  const matchingAccount = record.accountsData.find((account) => account._id === access);
                                  const accountName = matchingAccount ? matchingAccount.name : 'No Name';

                                  return (
                                      <button className="item-btn CursorNotallowed" key={index}>
                                        <img className="remove-icon" src={RmvIcon} alt="icon" />
                                        {accountName}
                                      </button>
                                  );
                                })

                            ) : (
                               <></>

                            )}
                          </div>
                        </>
                    ) : activeBtn === "Groups" ? (
                        <>
                          <div className="check-box-edit">
                            <label className="radio-button-label CursorNotallowed">
                              <input
                                  type="radio"
                                  value={data?.permissions?.groups?.type === "ReadOnly" ? "Read" : "ReadAndWrite"}
                                  checked={true}
                              />
                              <span className="radio-button-custom"></span>
                              {data?.permissions?.groups?.type === "ReadOnly" ? "Read" : "Read and Write"}
                            </label>
                          </div>
                          <div className="item-btn-div">
                            {Array.isArray(groupsAccess) ? (
                                groupsAccess.map((access, index) => {
                                  const matchingGroup = record.groupsData.find((group) => group._id === access);
                                  const groupName = matchingGroup ? matchingGroup.name : 'No Name';

                                  return (
                                      <button className="item-btn CursorNotallowed" key={index}>
                                        <img className="remove-icon" src={RmvIcon} alt="icon" />
                                        {groupName}
                                      </button>
                                  );
                                })

                            ):(

                                <></>
                            )}

                          </div>
                        </>
                    ) : activeBtn === "Secrets" ? (
                        <>
                          <div className="check-box-edit">
                            <label className="radio-button-label CursorNotallowed">
                              <input
                                  type="radio"
                                  value={data?.permissions?.secrets?.type === "ReadOnly" ? "Read" : "ReadAndWrite"}
                                  checked={true}
                              />
                              <span className="radio-button-custom"></span>
                              {data?.permissions?.secrets?.type === "ReadOnly" ? "Read" : "Read and Write"}
                            </label>
                          </div>
                          <div className="item-btn-div">
                            {Array.isArray(secretsAccess) ? (
                                secretsAccess.map((access, index) => {
                                  const matchingSecret = record.secretsData.find((secret) => secret.id === access);
                                  const secretName = matchingSecret ? matchingSecret.name : 'No Name';

                                  return (
                                      <button className="item-btn CursorNotallowed" key={index}>
                                        <img className="remove-icon" src={RmvIcon} alt="icon" />
                                        {secretName}
                                      </button>
                                  );
                                })
                            ) : (
                               <></>

                            )}
                            {/*{secretsAccess.map((access, index) => (*/}
                            {/*    <button className="item-btn CursorNotallowed" key={index}>*/}
                            {/*      <img*/}
                            {/*          className="remove-icon"*/}
                            {/*          src={RmvIcon}*/}
                            {/*          alt="icon"*/}
                            {/*      />*/}
                            {/*      {access}*/}
                            {/*    </button>*/}
                            {/*))}*/}
                          </div>

                        </>
                    ): activeBtn === "Nodes" ? (
                        <>
                        <div className="check-box-edit">
                          <label className="radio-button-label CursorNotallowed">
                            <input
                                type="radio"
                                value={data?.permissions?.nodes?.type === "ReadOnly" ? "Read" : "ReadAndWrite"}
                                checked={true}
                            />
                            <span className="radio-button-custom"></span>
                            {data?.permissions?.nodes?.type === "ReadOnly" ? "Read" : "Read and Write"}
                          </label>
                        </div>
                          <div className="item-btn-div">
                          {Array.isArray(nodesAccess) ? (
                              nodesAccess.map((access, index) => {
                                const matchingNode = record.nodesData.find((node) => node.id === access);
                                const nodeName = matchingNode ? matchingNode.name : 'No Name';

                                return (
                                    <button className="item-btn CursorNotallowed" key={index}>
                                      <img className="remove-icon" src={RmvIcon} alt="icon" />
                                      {nodeName}
                                    </button>
                                );
                              })
                          ) : (
                             <></>
                          )}
                          </div>
                        </>
                    ): activeBtn === "Domains" ? (
                        <>
                        <div className="check-box-edit">
                          <label className="radio-button-label CursorNotallowed">
                            <input
                                type="radio"
                                value={data?.permissions?.domains?.type === "ReadOnly" ? "Read" : "ReadAndWrite"}
                                checked={true}
                            />
                            <span className="radio-button-custom"></span>
                            {data?.permissions?.domains?.type === "ReadOnly" ? "Read" : "Read and Write"}
                          </label>
                        </div>
                        <div className="item-btn-div">
                          {Array.isArray(domainsAccess) ? (

                              domainsAccess.map((access, index) => {
                                const matchingDomain = record.domainsData.find((domain) => domain.id === access);
                                const domainName = matchingDomain ? matchingDomain.domain : 'No Name';

                                return (
                                    <button className="item-btn CursorNotallowed" key={index}>
                                      <img className="remove-icon" src={RmvIcon} alt="icon" />
                                      {domainName}
                                    </button>
                                );
                              })
                          ) : (
                              <></>

                          )}
                        </div>
                        </>
                    ):<></>}
                  </div>
                </>
            )}
          </div>
        </div>
      </div> : <div>Something Went Wrong</div>)}

    {/* Permissions Section ends here*/}
      {/*Delete POPUP Begins*/}
      <Modal
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={deleteModal}
          onHide={() => setDeleteModal(false)}

      >

        <Modal.Body className="confirm-modal-body">
          <h3 className="">Are you sure?</h3>
          <p>
            You want to go delete the account you will loose all the data that are saved? {data.full_name}
          </p>
          <div className="confirm-btns">
            <button className="conf-cancel" onClick={() => DeleteUser(data.id)}>Yes, Delete</button>
            <button className="conf-conf" onClick={() => setDeleteModal(false)}>No, don't delete</button>
          </div>
        </Modal.Body>
      </Modal>
      {/*Delete POPUP Begins*/}
    </>
  );
}
