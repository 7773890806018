import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Button from '../FormComponents/Button/Buttons';
import SearchBarInput from '../Integrations/SearchBar';
const HeadingInfoPanel = ({
  heading,
  description,
  SearchBar,
  ButtonName,
  isBreadCrumb,
  SearchBarWidth,
  className,
  children,
  btnNavigation,
  sethandleSearch,
  hrLine,
  buttonIcon,
  isBreadCrumbLink
}) => {
  const navigate = useNavigate();
  

  return (
    <div className={`${className} pos_fixed breadcrumb_sec ${children && "px-4 pt-4"}`}
      style={{ borderBottom: `${hrLine && "1px solid #DDE2E5"}`}}
    >
      {
        <div className="left_sec">
          {
            (isBreadCrumb?.length > 0 && heading && description) && (
              <Breadcrumb>
                <Breadcrumb.Item onClick={() => navigate(`/home${isBreadCrumbLink}`)} className="breadCrumb-group">
                  {/* <NavLink to={`home/users/${isBreadCrumb}`}>{isBreadCrumb}</NavLink> */}
                  {isBreadCrumb.charAt(0).toUpperCase() + isBreadCrumb.slice(1)}
                </Breadcrumb.Item>
                <Breadcrumb.Item active href="#">
                  {heading}
                </Breadcrumb.Item>
              </Breadcrumb>
            )
          }
          <div className="node-builder-heading">
            <div className="node-builder-main-heading">
              {children}
              {heading && <h1>{heading}</h1>}
              {description && <p className="sub_text">{description}</p>}
            </div>
          </div>
        </div>
      }
      <div className="right_sec w-50 align-self-start" style={{ height: '60px'}}>
        <div className="builder-input-chatbot justify-content-end m-0">
        {
          SearchBar && (
            <SearchBarInput
              className={`w-${SearchBarWidth}`}
              placeholder={"Search"}
              onChange={(e) => sethandleSearch(e.target.value)}
              // value={handleSearch}
            />
        )}
        

        {(!SearchBar && ButtonName || buttonIcon) && (
          <Button  value={ButtonName || buttonIcon} onClick={() => navigate("/home"+btnNavigation)}
            imgLeft={buttonIcon && <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.0013 3.16406C8.28635 3.16406 8.51743 3.39514 8.51743 3.68019V7.98127H12.8185C13.1036 7.98127 13.3346 8.21235 13.3346 8.4974C13.3346 8.78245 13.1036 9.01352 12.8185 9.01352H8.51743V13.3146C8.51743 13.5996 8.28635 13.8307 8.0013 13.8307C7.71625 13.8307 7.48517 13.5996 7.48517 13.3146V9.01352H3.1841C2.89905 9.01352 2.66797 8.78245 2.66797 8.4974C2.66797 8.21235 2.89905 7.98127 3.1841 7.98127H7.48517V3.68019C7.48517 3.39514 7.71625 3.16406 8.0013 3.16406Z" fill="white"/></svg>}
          />
        )}
        </div>
      </div>
  </div>

    
  )
}

export default HeadingInfoPanel