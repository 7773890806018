import { useFormik } from 'formik';
import { withFormikDevtools } from "formik-devtools-extension";
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from "yup";
import { createSecrets, getSecretsByID } from '../../api/secretManager';
import { APIKEYMANAGER_ID, INTEGRATION } from '../../util/routes';
import Button from "../FormComponents/Button/Buttons";
import Inputs from '../FormComponents/Input/Inputs';
import IntegrationHeader from "../HeadingInfoPanel";

const CreateSecretKey = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const routeName = location.pathname;
  const isCustom = routeName.includes("custom");
  const isAny = routeName.includes("any");
  const currentNodeId = routeName.split("/").pop();
  const [Disable, setDisable] = useState(false);
  const [formData, setFormData] = useState({});

  let apiKeyManagerCustom = APIKEYMANAGER_ID+"custom"

  const validationSchema = Yup.object({
    name: Yup.string().required(),
    // key: Yup.string().required(),
    value: Yup.string().required(),
    // category: Yup.string().required(),
  })
  

  useEffect(() => {
    (async () => {
      if (currentNodeId != "custom") {
        try {
          let getResp = await getSecretsByID(currentNodeId); 
          setFormData(getResp);
          formik.setValues(getResp)
          
        } catch (error) {
          console.log(error, '--->')
        }

      }
    })()
  
  }, [currentNodeId]);

  const formik = useFormik({
    initialValues:{},
    validationSchema: validationSchema,    

    onSubmit:async (values) => {
      setDisable(true)
      
      if (isCustom) {
        try {
          await createSecrets(values)
          toast.success("Created successfully")
          formik.resetForm();
          setTimeout(() => {
            navigate("/home"+apiKeyManagerCustom)
          }, 0);
        } catch (error) {
          console.log(error?.response?.data?.message, 'err')
          toast.error(error?.response?.data?.message?.toString().replace(",", " and "))
        }
      } else {
        try {
          await updateSecrets(values._id, values);
          toast.success("Updated Successfully")
          navigate("/home"+apiKeyManagerCustom)
          
        } catch (error) {
          toast.error(error?.response?.data?.message?.toString().replace(",", " and "))
        }

      }
      
    },
  });
  withFormikDevtools(formik);

  return (
    <>
    <IntegrationHeader
      heading={`${currentNodeId != "custom" ?  "Edit" : "Create"} Secret Key`}
      description={`${currentNodeId != "custom" ?  "Edit" : "Create"}  and save your secret API Keys.`}
      SearchBar={false}
      SearchBarWidth={100}
      isBreadCrumb={"integration"}
      isBreadCrumbLink={INTEGRATION}
    />
      <div className="box-container">
        <IntegrationHeader
          className={"bg-white"}
          hrLine={true}
        >
          <h1 style={{ fontSize: '20px'}}>{isCustom && "Create Secret Key" }</h1>
        </IntegrationHeader>
        <div className='cus-form mt-0 px-4'>
          <div className='block-box'>
            <Inputs
              label="Name"
              htmlFor="Name"
              type="text"
              id="name"
              placeholder='Input'
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.errors.name}
              // disabled={isDisable}
            />
          </div>
          <div className='block-box'>
            <Inputs
              label="Value"
              htmlFor="Value"
              type="textarea"
              id="value"
              className="textarea-input"
              placeholder='idflow.syntizen.com'
              value={formik.values.value}
              onChange={formik.handleChange}
              error={formik.errors.value}
            />
          </div>
          </div>
          <div className="saveEditButton px-4 pb-4">
            <Button variant="outlined" value="Cancel" onClick={() => navigate("/home"+apiKeyManagerCustom)} />
            <Button value="Create" onClick={formik.handleSubmit}/>
          </div>
      </div>
      </>
  )
}

export default CreateSecretKey;