import React, { useState,useEffect, useMemo,useRef } from "react";
import "./index.css";
import {Form, useParams, useNavigate, Link, useLoaderData} from "react-router-dom"
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import CustomSelect from "../core/CustomSelect";
import CustomInput from "../core/InputField";
import CustomButton from "../core/Button";
import RmvIcon from "../../assets/userScreenSvg/remove-icon.svg";
import {createUser, getUserByID, updateUser, getUser} from "../../api/users";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { createImage } from "../../api/accounts";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {notification, Select} from 'antd';
import { useFormik, Formik } from "formik";
import * as Yup from 'yup';
import {getAllGroups} from "../../api/groups";
import {getAllWorkFlow} from "../../api/workflow";
import {getAllAccounts} from "../../api/accounts";
import {getAllSecrets} from "../../api/secretManager";
import {getAllDomains} from "../../api/domain";
import {getAllNodes} from "../../api/nodes";
import {baseURLImage, setAccessToken, setEmail} from "../../api/common";
import Delete from "../../assets/delete.svg";
import EditLight from "../../assets/edit-light.svg";

const fetchData = async () => {
    try {
        const [workflowData, groupsData, accountsData, secretsData, domainsData,nodesData,userData] = await Promise.all([
            getAllWorkFlow('name'),
            getAllGroups('name'),
            getAllAccounts('name'),
            getAllSecrets('name'),
            getAllDomains(),
            getAllNodes('name'),
            getUser()
        ]);
        return { workflowData, groupsData, accountsData, secretsData, domainsData, nodesData, userData };
    } catch (error) {
        throw error;
    }
};
// Edit User


const NewUser = () => {


    // const params = useParams();
    const navigate = useNavigate();
    const [key, setKey] = useState("1");
    const [api, contextHolder] = notification.useNotification();
    const [data, setData] = useState({
        workflowData: [],
        groupsData: [],
        accountsData: [],
        secretsData: [],
        domainsData: [],
        nodesData: [],
        userData: []
    });



    const default_permissions = {

        is_admin: false,
        "phone_number": {
            "country_code": 91,
            "phone_number": 9999999999
        },
        permissions: {
            workflows: {
                type: "ReadOnly",
                access: 'All',
            },
            accounts: {
                type: "ReadOnly",
                access: 'All',
            },
            groups: {
                type: "ReadOnly",
                access: 'All',
            }, nodes: {
                type: "ReadOnly",
                access: 'All',
            }, secrets: {
                type: "ReadOnly",
                access: 'All',
            }, domains: {
                type: "ReadOnly",
                access: 'All',
            },
            others: {
                flow_builder: false,
                bulk: false,
                analytics: false,
                dashboard: false,
                billing: false,
                zapier: false,
            }
        }
        ,

    };
    // let initialUser =
    //     data && data.user
    //         ? data.user
    //         : {
    //             disabled: false,
    //             // organization: parseInt(organizationId),
    //             phone_number: { country_code: 91, phone_number: undefined },
    //             is_admin: true,
    //         };
    //
    // const [user, setUser] = useState(initialUser);
    const [selectedValues, setSelectedValues] = useState(default_permissions);
    const [numerror, setNumError] = useState(false);
    console.log("Data is **************", data)
    console.log("fetched Selected Data ===========", selectedValues);


    // const handleChangeIcon = (event) => {
    //     if (event.target.files.length > 0) {
    //         const image = event.target.files[0];
    //         const formData = new FormData();
    //         formData.append("file", image);
    //         createImage(formData)
    //             .then((res) => {
    //                 setSelectedValues((prev) => ({ ...prev, image: res }));
    //             })
    //             .catch((error) => {
    //                 showErrorMessage(error);
    //             });
    //     }
    // };
    const dailCode = useRef();
    const validate = (phone, country) => {
        if (phone === country?.dialCode) return;
        if (phone.match(/^(?:\+?(?:(?:\d{1,3}[\s-]?){1,2}\d{1,4})|\(\d{1,}\))$/)) {
            return "Invalid value";
        }
        dailCode.current = country?.dialCode;

        const number = parseInt(phone);

        // const isDuplicateNumber = group.contacts.some(
        //     (c) => c.phoneNumber === number
        // );
        // if (isDuplicateNumber) {
        //     return "Number already exits";
        // }
        // return "";
    };
    const handleChangeIcon = (event) => {
        if (event.target.files.length > 0) {
            const image = event.target.files[0];
            const reader = new FileReader();

            reader.onload = (e) => {
                const base64Image = e.target.result;
                setSelectedValues((prev) => ({...prev, image: base64Image}));
            };

            reader.readAsDataURL(image);
        }
    };
    let OrganizationId = data.userData.organization;
    console.log("organizationData", OrganizationId);



    const mergeValues = (formikValues, selectedValues) => {
        // Merge selectedValues into formikValues
        return {
            ...formikValues,
            ...selectedValues,
        };
    };


    const formik = useFormik({
        initialValues: {
            // full_name: '',
            // email: '',
            // organization_name:"m2p",
            organization_name: OrganizationId,
            ...selectedValues,
        },

        organization_name: OrganizationId,
        // validationSchema: Yup.object({
        //     full_name: Yup.string()
        //         .max(15, 'Must be 15 characters or less')
        //         .required('name required'),
        //     email: Yup.string().email('Invalid email address').required('Email required'),
        // }),
        onSubmit: async (values) => {
            const mergedValues = mergeValues(values, selectedValues);
            mergedValues.organization = OrganizationId;
            try {
                // const res = await createUser(mergedValues);
                const res = userId ? await updateUser(userId, mergedValues) : await createUser(mergedValues);
                toast.success("User " + (userId ? "updated" : "created") + " successfully!" ,{ autoClose: 1000 });
                setTimeout(() => {
                    navigate("/home/users");
                }, 2000);
            } catch (error) {
                toast.error("Something Went Worng!" ,{ autoClose: 1000 });
                console.error(error);
                if (error.response && error.response.status === 400) {
                    console.log("Bad Request: Invalid data");

                } else if (error.response && error.response.status === 500) {
                    console.log("Server Error: Something went wrong");

                } else {
                    console.log("An unexpected error occurred: " + error.message);
                }

            }
        },
    });


    // const formik = useFormik({
    //     initialValues: {
    //         selectedValues
    //     },
    //
    //     onSubmit: async (values) => {
    //         console.log("fdhgdhgdghdgdgdhgdhgdhg",values);
    //         try {
    //
    //             const res = await createUser(values);
    //
    //             if (res.status === 201) {
    //
    //                 console.log("User created successfully!");
    //
    //             } else {
    //                 console.log("Unexpected response status: " + res.status);
    //
    //             }
    //         } catch (error) {
    //             if (error.response && error.response.status === 400) {
    //                 console.log("Bad Request: Invalid data");
    //
    //             } else if (error.response && error.response.status === 500) {
    //                 console.log("Server Error: Something went wrong");
    //
    //             } else {
    //                 console.log("An unexpected error occurred: " + error.message);
    //             }
    //         } finally {
    //
    //         }
    //     },
    // });

    const CheckBox = ({name, checked, onChange, id}) => {
        return (
            <div>
                <div className="container_check">
                    <div className="check-box-edit">
                        <label className="radio-button-label">
                            <input
                                type="radio"
                                id={id || name}
                                checked={checked}
                                onChange={(e) => {
                                    onChange(e.target.checked);
                                }}
                            />
                            <span className="radio-button-custom"></span>
                            <label htmlFor={id || name}>{name}</label>
                        </label>

                    </div>

                </div>
            </div>
        );
    };

    // const PermissionBox = ({ name, items,selectedValues, setSelectedValues }) => {
    //     const isOthersTab = name ==="others";
    //     // Define the selected radio button state for Read and ReadAndWrite
    //     const [selectedType, setSelectedType] = useState(
    //         selectedValues.permissions[name]?.type || ''
    //     );
    //
    //     // Define the selected access state
    //     const selectedAccess = selectedValues.permissions[name]?.access || [];
    //
    //     // Function to handle radio button changes
    //     const handleRadioChange = (type) => {
    //         setSelectedType(type);
    //
    //         // Update the selectedValues state with the new type
    //         setSelectedValues((prevSelectedValues) => ({
    //             ...prevSelectedValues,
    //             permissions: {
    //                 ...prevSelectedValues.permissions,
    //                 [name]: {
    //                     ...prevSelectedValues.permissions[name],
    //                     type: type,
    //                     access: type === 'ReadOnly' ? [] : selectedAccess, // Clear access when changing type
    //                 },
    //             },
    //         }));
    //     };
    //
    //     // Function to handle access selection
    //     const handleAccessChange = (access) => {
    //         setSelectedValues((prevSelectedValues) => ({
    //             ...prevSelectedValues,
    //             permissions: {
    //                 ...prevSelectedValues.permissions,
    //                 [name]: {
    //                     ...prevSelectedValues.permissions[name],
    //                     access: access,
    //                 },
    //             },
    //         }));
    //     };
    //
    //
    //
    //     // const selectedValue = selectedValues[name] || []; // Use the specific tab's selected values
    //     return (
    //        <>
    //            <div>
    //                <div>
    //                    {!isOthersTab && (
    //                        <div className="d-flex gap_read">
    //                            <div>
    //                                <CheckBox
    //                                    id={`${name} - ReadOnly`}
    //                                    name={"Read Only"}
    //                                    checked={selectedType === "ReadOnly"}
    //                                    onChange={() => handleRadioChange("ReadOnly")}
    //                                />
    //                            </div>
    //                            <div>
    //                                <CheckBox
    //                                    id={`${name} - ReadAndWrite`}
    //                                    name={"Read and Write"}
    //                                    checked={selectedType === "ReadAndWrite"}
    //                                    onChange={() => handleRadioChange("ReadAndWrite")}
    //                                />
    //                            </div>
    //                        </div>
    //                    )}
    //                    <div>
    //                        <label className="label_edit">
    //                            Select {name}
    //                            {/* Commented out portion removed */}
    //                        </label>
    //                        {isOthersTab ? (
    //                            <>
    //
    //                                {Object.keys(items).map((itemKey) => (
    //                                    <CheckBox
    //                                        key={itemKey}
    //                                        name={itemKey}
    //                                        checked={selectedAccess.includes(itemKey)}
    //                                        onChange={() =>
    //                                            handleAccessChange(
    //                                                selectedAccess.includes(itemKey)
    //                                                    ? selectedAccess.filter((access) => access !== itemKey)
    //                                                    : [...selectedAccess, itemKey]
    //                                            )
    //                                        }
    //                                    />
    //                                ))}
    //                            </>
    //                        ) : (
    //                            <Select
    //                                mode="multiple"
    //                                placeholder="Select"
    //                                value={selectedAccess}
    //                                onChange={handleAccessChange}
    //                                style={{ width: "100%" }}
    //                                options={Object.keys(items).map((itemKey) => ({
    //                                    value: itemKey,
    //                                    label: items[itemKey].name,
    //                                }))}
    //                            />
    //                        )}
    //
    //                    </div>
    //                </div>
    //            </div>
    //
    //        </>
    //     );
    // };

    const PermissionBox = ({name, items, selectedValues, setSelectedValues}) => {
        const isOthersTab = name === "others";
        const [selectedType, setSelectedType] = useState(
        selectedValues?.permissions?.[name]?.type || ''
        );
        const selectedAccess = selectedValues?.permissions?.[name]?.access || [];
        const handleRadioChange = (type) => {
            setSelectedType(type);
            setSelectedValues((prevSelectedValues) => ({
                ...prevSelectedValues,
                permissions: {
                    ...prevSelectedValues.permissions,
                    [name]: {
                        ...prevSelectedValues.permissions[name],
                        type: type,
                        access: prevSelectedValues.permissions[name].type === type ? selectedAccess : prevSelectedValues.permissions[name].access,
                    },
                },
            }));
        };

        // Function to handle access selection
        const handleAccessChange = (access) => {
            if (access.includes('All')) {
                access = access.filter(item => item !== 'All'); // Remove 'All' from the array
            }

            setSelectedValues((prevSelectedValues) => {
                const updatedPermissions = {
                    ...prevSelectedValues.permissions,
                    [name]: {
                        type: selectedType,
                        access: access,
                    },
                };

                return {
                    ...prevSelectedValues,
                    permissions: updatedPermissions,
                };
            });
        };



        const handleOtherChange = (key) => {
            setSelectedValues((prevSelectedValues) => {
                const updatedPermissions = {
                    ...prevSelectedValues.permissions,
                    others: {
                        ...(prevSelectedValues.permissions.others || {}), // Create others object if not present
                        [key]: !prevSelectedValues.permissions.others?.[key], // Toggle the value
                    },
                };

                return {
                    ...prevSelectedValues,
                    permissions: updatedPermissions,
                };
            });
        };


        // Check if the name is "others"
        if (name === "others") {
            return (

                <div className="OthersCheckTab row">
                    <div className="col-lg-7">
                        <div className="row">
                            {Object.keys(default_permissions.permissions.others).map((key) => (
                                <div key={key} className="col-lg-4">
                                    <div className="checkbox-wrapper-33">
                                        <label className="checkbox">
                                            <input
                                                type="checkbox"
                                                className="checkbox__trigger visuallyhidden"
                                                id={`${name} - ${key}`}
                                                name={key}
                                                checked={
                                                    selectedValues.permissions?.others?.hasOwnProperty(key) &&
                                                    selectedValues.permissions.others[key]
                                                }
                                                onChange={() => handleOtherChange(key)}
                                            />
                                            <span className="checkbox__symbol">
          <svg xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 28 28" height="28px" width="28px" className="icon-checkbox" aria-hidden="true">
            <path d="M4 14l8 7L24 7"></path>
          </svg>
        </span>
                                            <p class="checkbox__textwrapper">{key}</p>
                                        </label>
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <div>
                        <div className="d-flex gap_read">
                            <div>
                                <CheckBox
                                    id={`${name} - ReadOnly`}
                                    name={"Read Only"}
                                    checked={selectedType === "ReadOnly"}
                                    onChange={() => handleRadioChange("ReadOnly")}
                                />
                            </div>
                            <div>
                                <CheckBox
                                    id={`${name} - ReadAndWrite`}
                                    name={"Read and Write"}
                                    checked={selectedType === "ReadAndWrite"}
                                    onChange={() => handleRadioChange("ReadAndWrite")}
                                />
                            </div>
                        </div>
                        <div>
                            <label className="label_edit UserSelectLabel">Select {name}</label>
                            <Select
                                mode="multiple"
                                placeholder="Select"
                                className="UserTeamSelect"
                                value={selectedAccess}
                                onChange={handleAccessChange}
                                style={{width: "100%"}}
                                options={items.map((item) => ({
                                    value: item._id || item.id,
                                    label: item.name || item.domain,
                                }))}
                            />
                        </div>
                    </div>
                </div>
            );
        }
    };


    const [loading, setLoading] = useState(true);
    const {userId} = useParams();
    const fetchUserData = async () => {
        try {
            const res = await getUserByID(userId);
            setSelectedValues(res);
        } catch (err) {
            console.log(err);
        }
    }
    useEffect(() => {
        fetchUserData();
    }, [userId]);

   useEffect(() => {
        fetchData()
            .then((result) => {
                setData(result);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });


    }, []);


    if (loading) {
        return <div>Loading...</div>;
    }

    const accessdata = [
        {
            id: "1",
            name: "workflows",
            value: "workflowData",
            isActive: true,
        },
        {
            id: "2",
            name: "accounts",
            value: "accountsData",
            isActive: false,
        }, {
            id: "3",
            name: "nodes",
            value: "nodesData",
            isActive: false,
        }, {
            id: "4",
            name: "domains",
            value: "domainsData",
            isActive: false,
        }, {
            id: "5",
            name: "secrets",
            value: "secretsData",
            isActive: false,
        },
        {
            id: "6",
            name: "groups",
            value: "groupsData",
            isActive: false,
        },
        {
            id: "7",
            name: "others",
            value: "others",
            isActive: false,
        },
    ];

    // Title declaration based on Edit and Create User

    // let title;
    // if (userId !== undefined && userId !== null) {
    //     title = 'Edit User';
    // } else {
    //     title = 'Create New User';
    // }
    // const mainTitle = UserId ? 'Edit Member' : 'Add New team Member';
    return (
        <div>
            <section className="UserTopLabelTeam">
                <li className="UserTopLabelTeamli">
                   <Link to={"/home/users"} className="TextDecorationNone"> <span>Team Members</span></Link> / {userId !== undefined && userId !== null ? 'Edit Member' : 'Add New Member'}
                </li>
                <h4 UserTopLabelTeamh>{userId !== undefined && userId !== null ? 'Edit Member' : 'Add New team Member'}</h4>
                <p className="UserTopLabelTeamP">Add a new team member and assign permissions accordingly</p>
            </section>
            <form onSubmit={formik.handleSubmit}>
                <div className="custom-container2 CreateContainer">
                    <h3 className="UserTopTitle">{userId !== undefined && userId !== null ? 'Edit User' : 'Create New User'}</h3>
                    <hr className="Hrline"/>


                        {userId !== undefined && userId !== null ? <>

                            <div className="d-flex flex-row justify-content-between sec2 align-items-center">
                                <div className="UserDetailsTop">

                                {data?.image ? (
                                    <img
                                        src={baseURLImage + data.image}
                                        alt={"icon"}
                                        height="45rem"
                                        width="45rem"
                                        className="rounded-img"
                                    />
                                ) : (
                                    <p className="mb-0 SplitUserDetailTitle">
                                        {selectedValues.full_name
                                            ? selectedValues.full_name.split(' ').map((word, index) =>
                                                index < 2 ? word[0] : ''
                                            ).join('')
                                            : 'NN'}

                                    </p>
                                )}
                                <div>
                                    <h3 className="UserDetailsSpanText m-0">{selectedValues.full_name ? selectedValues.full_name : "Name Not Available"}</h3>
                                    <div className="d-flex flex-row">
                                        <p className="UserDetailsData">
                                            {selectedValues?.phone_number?.country_code}{" "}
                                            {selectedValues?.phone_number?.number}
                                        </p>

                                    </div>

                                </div>
                                {data.is_admin ? (
                                    <div className="role-div">Admin</div>
                                ) : (
                                    <div className="role-div">User</div>
                                )}
                                </div>

                            </div>
                        </> : <></>}


                    <div className="row pb-30 pt-30">
                        <div className="col-lg-8">
                            <div className="d-flex flex-row justify-content-between w-100 gap-4 pt-15">
                                <div className="d-flex w-100 UserformGroup">
                                    <div className="full-field-title-edit mb-0"><label>Name</label></div>
                                    <input
                                        type="text"
                                        className="input-field"
                                        name="full_name"
                                        placeholder="Enter Name"
                                        defaultValue={selectedValues.full_name}

                                        onChange={(e) => {
                                            setSelectedValues({
                                                ...selectedValues,
                                                full_name: e.target.value,
                                            });

                                        }}
                                    />

                                    {/*{formik.touched.full_name && formik.errors.full_name ? (*/}
                                    {/*    <div className="errorRequired">{formik.errors.full_name}</div>*/}
                                    {/*) : null}*/}
                                    {/*<CustomInput*/}
                                    {/*    labelClass={"full-field-title-name"}*/}
                                    {/*    placeholder={"Enter Name"}*/}
                                    {/*    inputStyle={"input-custom-add h-5"}*/}
                                    {/*    onChange={(e) =>*/}
                                    {/*        setNewAccount((prev) => ({*/}
                                    {/*          ...prev,*/}
                                    {/*          full_name: e.target.value,*/}
                                    {/*        }))*/}
                                    {/*    }*/}
                                    {/*/>*/}

                                </div>

                                <div className="d-flex w-100 UserformGroup">

                                    <div className="full-field-title-edit mb-0"><label>Phone</label></div>
                                    <div className="phone-input-div">
                                        {/*<PhoneInput*/}
                                        {/*    country={"in"}*/}
                                        {/*    enableSearch={true}*/}
                                        {/*    // dropdownStyle={dropdownStyle}*/}
                                        {/*    inputProps={{*/}
                                        {/*        name: "",*/}
                                        {/*        required: true,*/}
                                        {/*    }}*/}
                                        {/*    onChange={(phone, country) => {*/}
                                        {/*        let country_id = parseInt(country.dialCode); // Use country.dialCode*/}
                                        {/*        let number = parseInt(phone.slice(country.dialCode.length)); // Use country.dialCode*/}
                                        {/*        setSelectedValues((prev) => ({*/}
                                        {/*            ...prev,*/}
                                        {/*            phone_number: {*/}
                                        {/*                number: number,*/}
                                        {/*                country_code: country_id,*/}
                                        {/*            },*/}
                                        {/*        }));*/}
                                        {/*    }}*/}
                                        {/*/>*/}

                                        <PhoneInput
                                            country="in"
                                            // value={
                                            //     selectedValues.phone_number.country_code && selectedValues.phone_number.number
                                            //         ? "+" +
                                            //         selectedValues.phone_number.country_code.toString() +
                                            //         selectedValues.phone_number.number.toString()
                                            //         : undefined
                                            // }
                                            value={
                                                selectedValues.phone_number &&
                                                selectedValues.phone_number.country_code &&
                                                selectedValues.phone_number.number
                                                    ? "+" +
                                                    selectedValues.phone_number.country_code.toString() +
                                                    selectedValues.phone_number.number.toString()
                                                    : undefined
                                            }
                                            inputProps={{
                                                name: "phone",
                                                className: "form-control",

                                            }}
                                            isValid={validate}
                                            // isValid={(value, country) => {
                                            //     if (
                                            //         value.match(
                                            //             /^(?:\+?(?:(?:\d{1,3}[\s-]?){1,2}\d{1,4})|\(\d{1,}\))$/
                                            //         )
                                            //     ) {
                                            //         setNumError(false);
                                            //         return "Invalid Number";
                                            //     } else {
                                            //         return setNumError(true);
                                            //     }
                                            // }}
                                            onChange={(phone, country) => {
                                                let country_id = parseInt(country.dialCode); // Use country.dialCode
                                                let number = parseInt(phone.slice(country.dialCode.length)); // Use country.dialCode
                                                setSelectedValues((prev) => ({
                                                    ...prev,
                                                    phone_number: {
                                                        number: number,
                                                        country_code: country_id,
                                                    },
                                                }));
                                            }}

                                        />

                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-row justify-content-between w-100 mt-2 gap-4">
                                <div className="d-flex w-100 UserformGroup">
                                    <div className="full-field-title-edit mb-0"><label>Email</label></div>
                                    <input
                                        type="email"
                                        className="input-field"
                                        name="email"
                                        placeholder="Enter Name"
                                        // value={formik.values.email}
                                        defaultValue={selectedValues.email}
                                        // onChange={formik.handleChange}
                                        // onBlur={formik.handleBlur}
                                        onChange={(e) => {
                                            setSelectedValues({
                                                ...selectedValues,
                                                email: e.target.value,
                                            });

                                        }}

                                    />
                                    {formik.touched.email && formik.errors.email ? (
                                        <div className="errorRequired">{formik.errors.email}</div>
                                    ) : null}
                                    {/*<CustomInput*/}
                                    {/*    labelClass={" full-field-title-edit"}*/}
                                    {/*    placeholder={"Abc@gmail.com"}*/}
                                    {/*    inputStyle={"input-custom-add h-5"}*/}
                                    {/*    // onChange={(e) =>*/}
                                    {/*    //     setNewAccount((prev) => ({*/}
                                    {/*    //       ...prev,*/}
                                    {/*    //       email: e.target.value,*/}
                                    {/*    //     }))*/}
                                    {/*    // }*/}
                                    {/*/>*/}
                                </div>

                                <div className="d-flex w-100 UserformGroup">
                                    <div className="full-field-title-edit mb-0"><label>Profile</label></div>
                                    <CustomInput
                                        type="file"
                                        id="file"
                                        labelClass={" full-field-title-edit"}
                                        placeholder={"Choose A Picture"}
                                        onChange={handleChangeIcon}
                                        uploadLabel="Upload"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex flex-column w-100">
                        {/*  Account Access Section Starts --====>*/}
                        <div className="bottom-sec-edit-2 p-4">
                            {userId !== undefined && userId !== null ? <>
                                <div className="bottom-sec-div">
                                    <p className="bottom-heading2">Account status</p>
                                    <div className="d-flex flex-row bottom-div1">
                                        <div>
                                            <label className="switch">
                                                <input
                                                    checked={selectedValues.disabled === true}
                                                    type="checkbox"
                                                    onChange={(e) => {
                                                        setSelectedValues({
                                                            ...selectedValues,
                                                            disabled: e.target.checked,
                                                        });
                                                    }}
                                                />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                        <div>
                                            <p className="bottom-role-title2">Active</p>
                                            <p className="bottom-desc">
                                                Account is currently active for use
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </> : <></>}

                            <div className="bottom-sec-div">
                                <p className="bottom-heading2">Admin Access</p>
                                <div className="d-flex flex-row bottom-div1 ">
                                    <div>
                                        <label className="switch">
                                            <input
                                                type="checkbox"
                                                checked={selectedValues.is_admin === true}
                                                onChange={(e) => {
                                                    setSelectedValues({
                                                        ...selectedValues,
                                                        is_admin: e.target.checked,
                                                    });
                                                }}
                                            />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>

                                    <div>
                                        <p className="bottom-role-title2">Admin</p>
                                        <p className="bottom-desc">Account is currently an admin</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*  Account section ends here=====----->*/}
                        {!selectedValues.is_admin && (
                            <div className="p-4">
                                <div className="">
                                    <h4 className="AccessTitle">Access</h4>
                                    <Tabs
                                        id="controlled-tab-example"
                                        activeKey={key}
                                        onSelect={(k) => {
                                            setKey(k);
                                        }}
                                        className="mb-3 AccessTabs"
                                    >
                                        {accessdata.map((item) => (
                                            <Tab key={item.id} eventKey={item.id} title={item.name}>
                                                <PermissionBox
                                                    name={item.name}
                                                    items={data[item.value] || []}
                                                    selectedValues={selectedValues || default_permissions}
                                                    setSelectedValues={setSelectedValues}
                                                />
                                            </Tab>
                                        ))}
                                    </Tabs>
                                </div>
                            </div>
                        )}


                    </div>
                    <div className="row">
                        <div className="col-lg-8"></div>
                        <div className="col-lg-4 d-flex justify-content-end">
                            <div className="UserTeamSaveCancel">
                                <button
                                    className="UserTeamCancel"
                                >
                                    cancel
                                </button>
                                <button
                                    className="UserTeamSave"
                                    type={"submit"}
                                >
                                    {userId !== undefined && userId !== null ? 'Save' : 'Create'}

                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

        </div>

    );
};

export default NewUser;