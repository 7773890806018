import { LoginRequestV1, commonRequestV1 } from "./common";
const path = (user) => "/users/" + user;

const orgPath =() => "/organizations/register"

export const getUser = () => {
  return commonRequestV1("get", path("me"));
};

export const login = (user) => {
  return LoginRequestV1("post", path("login"), user);
};

export const checkSSO = (user) => {
  return LoginRequestV1("get", path("login/check/sso"), user)
}


export const loginWithSSO = (user) => {
  return LoginRequestV1("post", path("login/sso"), user)
}

export const loginEmail = (user) => {
  return LoginRequestV1("post", path("login/email"), user);
};



export const socialLogin = (user) => {
  return LoginRequestV1("post", path("login/social"), user);
}

export const loginWorkosCall = (user) => {
  return LoginRequestV1("post", path("login/workos/callback"), user);
}

export const sendEmail = (email) => {
  return LoginRequestV1("post", path(email));
};

export const createUser = (user) => {
  return commonRequestV1("post", path(""), user);


}
// export const createUser = (user, onSuccess) => {
//   return commonRequestV1("post", orgPath(""), user, onSuccess);
// };

export const createOrg = (user, onSuccess) => {
  return commonRequestV1("post", orgPath(""), user, onSuccess);
};

export const updateUser = (id, body) => {
  return commonRequestV1("put", path(id), body);
};
export const updateUserPassword = (body) => {
  // console.log({body});
  return commonRequestV1("put", path(`${body.email}/password?password=${body.password}&token=${body.token}`));
};


export const deleteUser = (user, onSuccess) => {
  return commonRequestV1("delete", path(user), {}, onSuccess);
};

export const sendResetEmail = (email) => {
  return commonRequestV1("post", path(`${email}/request_reset`));
};

export const verifyOTP = (user) => {
  return commonRequestV1("post", path("verify"), user);
};

export const resetPassword = (user) => {
  return commonRequestV1("post", path("reset"), user);
};

export const getAllUsers = (
  limit =100 ,
  skip = 0,
  search = "",
  sortOrder = "desc",
  sortBy = "created_at",
  filter = ""
) => {
  return commonRequestV1(
    "get",
    path(
      `?limit=${limit}&skip=${skip}&search=${search}&filter=${filter}&sortOrder=${sortOrder}&sortBy=${sortBy}`
    )
  );
};

export const fetchTenant = (id) => {
  return commonRequestV1("get", path(`tenant/avl/${id}`));
}

export const getUserByID = (id) => {
  return commonRequestV1("get", path(id));
};
export const checkUserRegister = (body) => {
  return commonRequestV1("post", path("verify/token"), body);
};

