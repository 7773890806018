import React from 'react';
import NotifcationHeadingPanel from '../HeadingInfoPanel';

const NotificationTemplate = () => {
  return (
    <NotifcationHeadingPanel
      heading={"Notifications"}
      description={"Create new set of templates for notifications"}
      buttonIcon={"Create Template"}
      
    />
  )
}

export default NotificationTemplate