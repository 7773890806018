import Button from '../../FormComponents/Button/Buttons';


export function ServiceCard(props) {
    const {item, btnHandleClick} = props;
    return (<div className="inte-cards">
        <div className="cardHead">
            {item.logo && <img src={item.logo} alt="" />}
            <h1>{item.name}</h1>
            {item.learnMore && <p>{item.learnMore}</p>}
        </div>
        <div className="cardBody">
            <p className="m-0">{item.desc}</p>
            {item.link && <span>{item.link}</span>}
        </div>
        {
            item.btnName && <Button 
            className={item?.isConnected ? "inte-btn" : ""} 
            imgLeft={item?.btnIcon && <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.0013 3.16406C8.28635 3.16406 8.51743 3.39514 8.51743 3.68019V7.98127H12.8185C13.1036 7.98127 13.3346 8.21235 13.3346 8.4974C13.3346 8.78245 13.1036 9.01352 12.8185 9.01352H8.51743V13.3146C8.51743 13.5996 8.28635 13.8307 8.0013 13.8307C7.71625 13.8307 7.48517 13.5996 7.48517 13.3146V9.01352H3.1841C2.89905 9.01352 2.66797 8.78245 2.66797 8.4974C2.66797 8.21235 2.89905 7.98127 3.1841 7.98127H7.48517V3.68019C7.48517 3.39514 7.71625 3.16406 8.0013 3.16406Z" fill="white"/></svg>}
            variant={item?.isConnected && "outlined"}
            onClick={() => btnHandleClick(props.item)}
            value={item.btnName} />
        }
    </div>);
}

export function ServiceCardList(props) {
    const {item, btnHandleClick} = props;

    return (
        <div className='card-list'>
            <div className="cardHead">
                <img src={item.logo} alt="" />
                <h1>{item?.name}</h1>
                <p className="m-0">{item?.desc}</p>
                {item?.learnMore && <span className="m-0">{item?.learnMore}</span>}
            </div>
            {item.btnName && <Button className={item?.isConnected ? "inte-btn" : ""} imgLeft={item?.btnIcon && <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.0013 3.16406C8.28635 3.16406 8.51743 3.39514 8.51743 3.68019V7.98127H12.8185C13.1036 7.98127 13.3346 8.21235 13.3346 8.4974C13.3346 8.78245 13.1036 9.01352 12.8185 9.01352H8.51743V13.3146C8.51743 13.5996 8.28635 13.8307 8.0013 13.8307C7.71625 13.8307 7.48517 13.5996 7.48517 13.3146V9.01352H3.1841C2.89905 9.01352 2.66797 8.78245 2.66797 8.4974C2.66797 8.21235 2.89905 7.98127 3.1841 7.98127H7.48517V3.68019C7.48517 3.39514 7.71625 3.16406 8.0013 3.16406Z" fill="white" /></svg>} variant={item?.isConnected && "outlined"} onClick={() => btnHandleClick(props.item)} value={item.btnName} />}
        </div>
    );
}