import React, { useState } from "react";
import { Breadcrumb } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import CustomButton from "../core/Button";
import CustomInput from "../core/InputField";
import { getEmail } from "../../api/common";
import { resetPassword } from "../../api/users";
import { ToastContainer, toast } from "react-toastify";
import { ForgetMsg } from "../../util/helper";
import './index.css'
import { notification } from 'antd';
import '../../styles/global.css';

export default function changePassword() {
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const emailAddress = getEmail();
  const [validPassword, setValidPassword] = useState(false)
  const [api, contextHolder] = notification.useNotification();


  const [loginData, setLoginData] = useState({
    password: "",
    newPassword: "",
    currentPassword: ""
  });

  const [loader, setLoader] = useState(false);

  const showErrorMessage = (err) => {
    api.error({
      description: err ? err : "Failed to update password",
      placement: "bottomRight",
      duration: 2,
      className: 'toast-error-message'
    })
  };

  const showSuccessMessage = (success) => {
    api.success({
      description: success ? success : "Password updated successfully",
      placement: "bottomRight",
      duration: 2,
      className: 'toast-success-message'
    })
  };
  const handleSignup = () => {
    const value = validatePassword(loginData.password);
    setValidPassword(value);
    if (loginData?.currentPassword === "" || loginData.password === "" || loginData.newPassword === "") {
      showErrorMessage("Please enter password");
    } else if (value) {
      setLoader(true);
      if (loginData.password === loginData.newPassword) {
        const data = {
          email: emailAddress,
          password: loginData.password,
          currentPassword: loginData.currentPassword
        };
        resetPassword(data)
          .then((res) => {
            if (res) {
              setLoader(false)
              showSuccessMessage("Password updated successfully");

              setTimeout(() => {
                // navigate("/user/login");
                localStorage.removeItem('accessToken');
                navigate('/user/login');
              }, 2000);
            }
          })
          .catch((err) => {
            setLoader(false)
            console.log(err);
            showErrorMessage(err?.response?.data?.message ? err?.response?.data?.message : 'Unable to update password. Please try again');
          });
      } else {
        setLoader(false)
        showErrorMessage("Password not matched");
      }
      // showSuccessMessage("Password updadted succesfuly");
    }
    else {
      let isPassword = true;
      showErrorMessage(<ForgetMsg />, isPassword);
    }
  };
  const validatePassword = (password) => {
    if (password.length < 8) {
      return false;
    }

    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
    if (!specialCharRegex.test(password)) {
      return false;
    }

    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    if (!uppercaseRegex.test(password) || !lowercaseRegex.test(password)) {
      return false;
    }
    const numberRegex = /\d/;
    if (!numberRegex.test(password)) {
      return false;
    }

    return true;
  };

  const navigate = useNavigate();


  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSignup();
    }
  }

  return (
    <>


      <div className="row profile-main">
        <Breadcrumb>
          <Breadcrumb.Item onClick={() => navigate("/home/users/profile")}>Profile</Breadcrumb.Item>
          <Breadcrumb.Item active>
            Change Password
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="col-lg-6">

          <div className="chan-pwd">
            <div>
              <h1>Change Password</h1>
              <p>Please provide your current password in order to set a new password.</p>
            </div>
            <div className="mt-5">
              <svg width="427" height="329" viewBox="0 0 427 329" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M101.377 156.31C97.2991 146.71 88.2369 140.242 79.1731 135.081C64.2573 126.589 47.7879 118.318 39.355 102.62C31.4919 87.9825 30.3443 69.4771 37.792 54.4395C51.3479 27.0639 93.7095 16.6855 117.05 38.5182C126.286 47.1572 130.514 59.0674 132.033 71.2336C134.004 87.0238 134.094 103.657 141.645 117.664C143.936 121.911 146.934 125.741 150.44 129.04" stroke="#060606" strokeWidth="0.793646" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M287.955 169.747C287.955 167.431 286.078 165.555 283.763 165.555C281.447 165.555 279.57 167.431 279.57 169.747C279.57 170.979 280.101 172.086 280.947 172.853V181.173H286.578V172.853C287.422 172.086 287.955 170.979 287.955 169.747Z" stroke="#060606" strokeWidth="0.793646" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M192.121 143.477V237.705C192.121 240.716 189.54 243.158 186.358 243.158H50.7669C47.5849 243.158 45.0039 240.716 45.0039 237.705V143.477C45.0039 140.465 47.5849 138.023 50.7669 138.023H186.358C189.54 138.023 192.121 140.464 192.121 143.477Z" fill="#F4FBFF" />
                <path d="M192.121 143.477V153.667H45.0039V143.477C45.0039 140.465 47.5849 138.023 50.7669 138.023H186.358C189.54 138.023 192.121 140.464 192.121 143.477Z" fill="#212121" />
                <path d="M192.121 143.477V237.705C192.121 240.717 189.54 243.158 186.358 243.158H50.7669C47.5849 243.158 45.0039 240.717 45.0039 237.705V143.477C45.0039 140.465 47.5849 138.023 50.7669 138.023H186.358C189.54 138.023 192.121 140.464 192.121 143.477Z" stroke="#060606" strokeWidth="0.793646" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M192.121 143.477V153.667H45.0039V143.477C45.0039 140.465 47.5849 138.023 50.7669 138.023H186.358C189.54 138.023 192.121 140.464 192.121 143.477Z" stroke="#060606" strokeWidth="0.793646" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M180.473 148.328C181.831 148.328 182.932 147.215 182.932 145.844C182.932 144.472 181.831 143.359 180.473 143.359C179.115 143.359 178.014 144.472 178.014 145.844C178.014 147.215 179.115 148.328 180.473 148.328Z" fill="white" />
                <path d="M170.908 148.328C172.267 148.328 173.368 147.215 173.368 145.844C173.368 144.472 172.267 143.359 170.908 143.359C169.55 143.359 168.449 144.472 168.449 145.844C168.449 147.215 169.55 148.328 170.908 148.328Z" fill="white" />
                <path d="M160.69 148.328C162.048 148.328 163.149 147.215 163.149 145.844C163.149 144.472 162.048 143.359 160.69 143.359C159.331 143.359 158.23 144.472 158.23 145.844C158.23 147.215 159.331 148.328 160.69 148.328Z" fill="white" />
                <path d="M180.473 148.328C181.831 148.328 182.932 147.215 182.932 145.844C182.932 144.472 181.831 143.359 180.473 143.359C179.115 143.359 178.014 144.472 178.014 145.844C178.014 147.215 179.115 148.328 180.473 148.328Z" stroke="#060606" strokeWidth="0.692157" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M170.908 148.328C172.267 148.328 173.368 147.215 173.368 145.844C173.368 144.472 172.267 143.359 170.908 143.359C169.55 143.359 168.449 144.472 168.449 145.844C168.449 147.215 169.55 148.328 170.908 148.328Z" stroke="#060606" strokeWidth="0.692157" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M160.69 148.328C162.048 148.328 163.149 147.215 163.149 145.844C163.149 144.472 162.048 143.359 160.69 143.359C159.331 143.359 158.23 144.472 158.23 145.844C158.23 147.215 159.331 148.328 160.69 148.328Z" stroke="#060606" strokeWidth="0.692157" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M172.991 223.832H70.8225C67.3906 223.832 64.4833 220.108 64.0071 215.103C63.4278 209.005 66.6427 203.562 70.8225 203.562H172.991C177.171 203.562 180.386 209.005 179.806 215.103C179.33 220.108 176.423 223.832 172.991 223.832Z" fill="#ECECEC" />
                <path d="M78.8272 215.772C80.1448 215.772 81.213 214.703 81.213 213.386C81.213 212.068 80.1448 211 78.8272 211C77.5096 211 76.4414 212.068 76.4414 213.386C76.4414 214.703 77.5096 215.772 78.8272 215.772Z" fill="#212121" />
                <path d="M88.0635 215.772C89.3811 215.772 90.4493 214.703 90.4493 213.386C90.4493 212.068 89.3811 211 88.0635 211C86.7459 211 85.6777 212.068 85.6777 213.386C85.6777 214.703 86.7459 215.772 88.0635 215.772Z" fill="#212121" />
                <path d="M97.2979 215.772C98.6155 215.772 99.6837 214.703 99.6837 213.386C99.6837 212.068 98.6155 211 97.2979 211C95.9803 211 94.9121 212.068 94.9121 213.386C94.9121 214.703 95.9803 215.772 97.2979 215.772Z" fill="#212121" />
                <path d="M106.534 215.772C107.852 215.772 108.92 214.703 108.92 213.386C108.92 212.068 107.852 211 106.534 211C105.217 211 104.148 212.068 104.148 213.386C104.148 214.703 105.217 215.772 106.534 215.772Z" fill="#212121" />
                <path d="M115.769 215.772C117.086 215.772 118.154 214.703 118.154 213.386C118.154 212.068 117.086 211 115.769 211C114.451 211 113.383 212.068 113.383 213.386C113.383 214.703 114.451 215.772 115.769 215.772Z" fill="#212121" />
                <path d="M125.003 215.772C126.321 215.772 127.389 214.703 127.389 213.386C127.389 212.068 126.321 211 125.003 211C123.685 211 122.617 212.068 122.617 213.386C122.617 214.703 123.685 215.772 125.003 215.772Z" fill="#212121" />
                <path d="M134.239 215.772C135.557 215.772 136.625 214.703 136.625 213.386C136.625 212.068 135.557 211 134.239 211C132.922 211 131.854 212.068 131.854 213.386C131.854 214.703 132.922 215.772 134.239 215.772Z" fill="#212121" />
                <path d="M143.474 215.772C144.791 215.772 145.859 214.703 145.859 213.386C145.859 212.068 144.791 211 143.474 211C142.156 211 141.088 212.068 141.088 213.386C141.088 214.703 142.156 215.772 143.474 215.772Z" fill="#212121" />
                <path d="M152.708 215.772C154.026 215.772 155.094 214.703 155.094 213.386C155.094 212.068 154.026 211 152.708 211C151.39 211 150.322 212.068 150.322 213.386C150.322 214.703 151.39 215.772 152.708 215.772Z" fill="#212121" />
                <path d="M161.944 215.772C163.262 215.772 164.33 214.703 164.33 213.386C164.33 212.068 163.262 211 161.944 211C160.627 211 159.559 212.068 159.559 213.386C159.559 214.703 160.627 215.772 161.944 215.772Z" fill="#212121" />
                <path d="M129.042 197.118H114.264C111.598 197.118 109.438 194.957 109.438 192.292V176.234H133.867V192.293C133.868 194.958 131.707 197.118 129.042 197.118Z" stroke="#060606" strokeWidth="0.793646" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M133.869 168.43C133.869 164.749 130.885 161.766 127.205 161.766H116.105C112.425 161.766 109.441 164.749 109.441 168.43V176.233" stroke="#060606" strokeWidth="0.793646" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M124.206 184.468C124.206 183.059 123.064 181.914 121.652 181.914C120.242 181.914 119.098 183.057 119.098 184.468C119.098 185.219 119.421 185.894 119.936 186.361V191.429H123.366V186.361C123.883 185.892 124.206 185.218 124.206 184.468Z" fill="#212121" />
                <path d="M246.406 84.1405C276.516 61.9581 319.589 52.5758 351.807 72.8393C361.937 79.2113 371.592 86.7246 378.495 96.5019C383.364 103.397 386.736 111.24 389.458 119.229C395.1 135.794 398.078 153.617 395.163 170.87C392.248 188.123 382.894 204.75 368.014 213.956C359.473 219.24 349.615 221.882 340.21 225.409C330.806 228.936 321.365 233.719 315.53 241.894C309.695 250.069 308.674 262.399 315.591 269.68C322.74 277.204 335.595 276.485 344.203 270.684C359.838 260.148 362.986 237.122 355.552 219.797C351.685 210.785 345.166 200.777 337.077 195.095C335.187 193.768 330.506 191.997 327.688 191.949" stroke="#060606" strokeWidth="0.793646" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M398.473 180.107L394.762 189.013L379.443 182.63L383.155 173.724C385.392 168.355 390.635 165.43 394.865 167.193C399.095 168.954 400.711 174.737 398.473 180.107Z" fill="#939393" />
                <path d="M394.761 189.008L392.405 194.663C390.65 198.874 387.76 202.487 384.233 204.686C379.733 207.491 376.897 206.309 375.722 201.141C374.8 197.088 375.33 192.491 377.085 188.28L379.441 182.625L394.761 189.008Z" fill="#ECECEC" />
                <path d="M394.761 189.008L392.405 194.663C390.65 198.874 387.76 202.487 384.233 204.686C379.733 207.491 376.897 206.309 375.722 201.141C374.8 197.088 375.33 192.491 377.085 188.28L379.441 182.625L394.761 189.008Z" stroke="#060606" strokeWidth="0.855789" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M398.473 180.107L394.762 189.013L379.443 182.63L383.155 173.724C385.392 168.355 390.635 165.43 394.865 167.193C399.095 168.954 400.711 174.737 398.473 180.107Z" stroke="#060606" strokeWidth="0.855789" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M394.863 167.19L399.867 155.18" stroke="#060606" strokeWidth="0.964148" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M378.73 205.906L373.58 218.265" stroke="#060606" strokeWidth="0.964148" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M327.128 191.655L310.307 182.868C306.41 180.832 302.002 181.394 300.527 184.195C300.492 184.259 300.461 184.324 300.43 184.39C299.124 187.196 301.201 191.015 305.006 193.003L311.475 196.383L309.91 191.956" stroke="#060606" strokeWidth="0.793646" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M329.969 195.712C331.391 195.712 332.544 194.559 332.544 193.137C332.544 191.715 331.391 190.562 329.969 190.562C328.547 190.562 327.395 191.715 327.395 193.137C327.395 194.559 328.547 195.712 329.969 195.712Z" stroke="#060606" strokeWidth="0.793646" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M215.84 219.057C214.908 224.094 214.347 229.455 216.26 234.207C216.769 235.474 217.493 236.721 218.647 237.449C220.437 238.579 222.88 238.159 224.618 236.949C226.354 235.739 227.52 233.884 228.542 232.029C233.897 222.299 236.555 211.102 236.141 200.002C236.024 196.875 235.601 193.576 233.682 191.104C227.812 183.541 224.123 192.444 222.195 197.226C219.355 204.268 217.222 211.593 215.84 219.057Z" fill="#F5F5F5" />
                <path d="M215.84 219.057C214.908 224.094 214.347 229.455 216.26 234.207C216.769 235.474 217.493 236.721 218.647 237.449C220.437 238.579 222.88 238.159 224.618 236.949C226.354 235.739 227.52 233.884 228.542 232.029C233.897 222.299 236.555 211.102 236.141 200.002C236.024 196.875 235.601 193.576 233.682 191.104C227.812 183.541 224.123 192.444 222.195 197.226C219.355 204.268 217.222 211.593 215.84 219.057Z" stroke="#060606" strokeWidth="0.793646" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M138.713 271.171L134.098 302.726H187.965L192.605 271.171C200.305 255.711 204.5 236.477 203.473 211.617C206.104 207.434 208.734 203.251 211.365 199.068C213.379 195.866 215.451 192.691 217.411 189.456C219.656 185.748 224.367 178.3 223.06 173.947C221.48 168.688 216.777 168.815 213.548 172.651C205.89 181.752 198.871 191.608 191.901 201.243C194.101 198.201 195.769 193.948 197.636 190.53C200.893 184.574 203.665 178.77 206.047 172.415C207.435 168.713 211.328 160.63 207.829 157.328C204.733 154.405 199.441 156.975 197.546 160.198L179.189 191.409C180.929 187.066 182.67 182.722 184.411 178.378C185.788 174.943 187.164 171.508 188.541 168.075C190.098 164.19 193.725 159.963 192.515 155.61C191.423 151.682 187.217 149.263 183.545 151.765C178.745 155.034 176.91 162.524 174.945 167.65C173.213 172.167 171.48 176.684 169.748 181.201C167.486 187.1 165.224 192.999 162.963 198.898C162.963 198.898 173.816 157.493 173.816 157.492C174.974 153.07 171.689 148.987 167.061 149.861C162.263 150.767 160.577 157.585 159.003 161.448C154.036 173.638 150.459 186.321 146.637 198.899L138.713 271.171Z" fill="#F5F5F5" />
                <path d="M138.713 271.171L134.098 302.726H187.965L192.605 271.171C200.305 255.711 204.5 236.477 203.473 211.617C206.104 207.434 208.734 203.251 211.365 199.068C213.379 195.866 215.451 192.691 217.411 189.456C219.656 185.748 224.367 178.3 223.06 173.947C221.48 168.688 216.777 168.815 213.548 172.651C205.89 181.752 198.871 191.608 191.901 201.243C194.101 198.201 195.769 193.948 197.636 190.53C200.893 184.574 203.665 178.77 206.047 172.415C207.435 168.713 211.328 160.63 207.829 157.328C204.733 154.405 199.441 156.975 197.546 160.198L179.189 191.409C180.929 187.066 182.67 182.722 184.411 178.378C185.788 174.943 187.164 171.508 188.541 168.075C190.098 164.19 193.725 159.963 192.515 155.61C191.423 151.682 187.217 149.263 183.545 151.765C178.745 155.034 176.91 162.524 174.945 167.65C173.213 172.167 171.48 176.684 169.748 181.201C167.486 187.1 165.224 192.999 162.963 198.898C162.963 198.898 173.816 157.493 173.816 157.492C174.974 153.07 171.689 148.987 167.061 149.861C162.263 150.767 160.577 157.585 159.003 161.448C154.036 173.638 150.459 186.321 146.637 198.899L138.713 271.171Z" stroke="#060606" strokeWidth="0.793646" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M204.488 253.787H157.58C156.148 253.787 154.986 252.625 154.986 251.194V162.789C154.986 161.357 156.148 160.195 157.58 160.195H204.488C205.92 160.195 207.082 161.357 207.082 162.789V251.194C207.083 252.625 205.921 253.787 204.488 253.787Z" fill="#ECECEC" />
                <path d="M204.488 253.787H157.58C156.148 253.787 154.986 252.625 154.986 251.194V162.789C154.986 161.357 156.148 160.195 157.58 160.195H204.488C205.92 160.195 207.082 161.357 207.082 162.789V251.194C207.083 252.625 205.921 253.787 204.488 253.787Z" stroke="#060606" strokeWidth="0.793646" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M202.396 251.526H159.671C158.336 251.526 157.254 250.444 157.254 249.109V164.862C157.254 163.527 158.336 162.445 159.671 162.445H202.396C203.731 162.445 204.813 163.527 204.813 164.862V249.108C204.815 250.444 203.731 251.526 202.396 251.526Z" fill="#F4FBFF" />
                <path d="M202.396 251.526H159.671C158.336 251.526 157.254 250.444 157.254 249.109V164.862C157.254 163.527 158.336 162.445 159.671 162.445H202.396C203.731 162.445 204.813 163.527 204.813 164.862V249.108C204.815 250.444 203.731 251.526 202.396 251.526Z" stroke="#060606" strokeWidth="0.793646" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M179.593 169.206C180.832 169.206 181.837 168.202 181.837 166.962C181.837 165.723 180.832 164.719 179.593 164.719C178.354 164.719 177.35 165.723 177.35 166.962C177.35 168.202 178.354 169.206 179.593 169.206Z" stroke="#060606" strokeWidth="0.793646" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M138.713 271.168C131.904 256.719 131.895 240.862 132.41 225.15C132.504 222.286 132.599 219.41 133.128 216.595C133.681 213.645 134.702 210.807 135.723 207.984C138.04 201.585 140.39 195.191 142.369 188.677C144.28 182.388 144.968 175.693 147 169.491C147.31 168.545 147.696 167.588 148.399 166.882C150.164 165.11 153.028 165.889 154.83 167.118C157.695 169.073 159.622 172.199 160.624 175.52C161.627 178.841 161.77 182.363 161.623 185.828C161.358 192.084 160.174 198.261 159.044 204.421C158.454 207.636 157.875 210.869 157.812 214.137C157.665 221.758 160.31 229.117 161.733 236.605C163.702 246.973 163.31 257.781 160.593 267.978L138.713 271.168Z" fill="#F5F5F5" />
                <path d="M138.713 271.168C131.904 256.719 131.895 240.862 132.41 225.15C132.504 222.286 132.599 219.41 133.128 216.595C133.681 213.645 134.702 210.807 135.723 207.984C138.04 201.585 140.39 195.191 142.369 188.677C144.28 182.388 144.968 175.693 147 169.491C147.31 168.545 147.696 167.588 148.399 166.882C150.164 165.11 153.028 165.889 154.83 167.118C157.695 169.073 159.622 172.199 160.624 175.52C161.627 178.841 161.77 182.363 161.623 185.828C161.358 192.084 160.174 198.261 159.044 204.421C158.454 207.636 157.875 210.869 157.812 214.137C157.665 221.758 160.31 229.117 161.733 236.605C163.702 246.973 163.31 257.781 160.593 267.978" stroke="#060606" strokeWidth="0.793646" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M271.914 279.586C256.974 279.502 244.013 272.787 231.414 265.665C229.119 264.368 226.813 263.062 224.755 261.4C222.6 259.657 220.75 257.548 218.915 255.444C214.754 250.674 210.613 245.878 206.203 241.358C201.945 236.995 196.792 233.545 192.661 229.11C192.03 228.434 191.427 227.686 191.173 226.762C190.539 224.443 192.494 222.232 194.328 221.154C197.245 219.439 200.688 219.055 203.862 219.573C207.036 220.092 209.981 221.458 212.745 223.058C217.735 225.947 222.235 229.618 226.745 233.232C229.1 235.12 231.476 237.005 234.117 238.446C240.275 241.807 247.506 242.582 254.279 244.495C263.656 247.143 272.305 252.075 279.386 258.811L271.914 279.586Z" fill="#F5F5F5" />
                <path d="M271.914 279.586C256.974 279.502 244.013 272.787 231.414 265.665C229.119 264.368 226.813 263.062 224.755 261.4C222.6 259.657 220.75 257.548 218.915 255.444C214.754 250.674 210.613 245.878 206.203 241.358C201.945 236.995 196.792 233.545 192.661 229.11C192.03 228.434 191.427 227.686 191.173 226.762C190.539 224.443 192.494 222.232 194.328 221.154C197.245 219.439 200.688 219.055 203.862 219.573C207.036 220.092 209.981 221.458 212.745 223.058C217.735 225.947 222.235 229.618 226.745 233.232C229.1 235.12 231.476 237.005 234.117 238.446C240.275 241.807 247.506 242.582 254.279 244.495C263.656 247.143 272.305 252.075 279.386 258.811L271.914 279.586Z" stroke="#060606" strokeWidth="0.793646" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M177.104 188.184C178.851 185.158 183.218 185.158 184.965 188.184L192.226 200.757L199.486 213.331C201.233 216.357 199.049 220.139 195.556 220.139H181.037H166.518C163.023 220.139 160.841 216.357 162.588 213.331L169.848 200.757L177.104 188.184Z" fill="#939393" />
                <path d="M177.104 188.184C178.851 185.158 183.218 185.158 184.965 188.184L192.226 200.757L199.486 213.331C201.233 216.357 199.049 220.139 195.556 220.139H181.037H166.518C163.023 220.139 160.841 216.357 162.588 213.331L169.848 200.757L177.104 188.184Z" stroke="#060606" strokeWidth="0.793646" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M183.134 197.936L181.787 209.22H180.284L178.937 197.936C178.787 196.677 179.769 195.57 181.037 195.57C182.302 195.57 183.284 196.677 183.134 197.936ZM180.199 212.536V212.499C180.199 212.037 180.573 211.663 181.034 211.663C181.496 211.663 181.869 212.038 181.869 212.499V212.536C181.869 213 181.491 213.375 181.028 213.372C180.569 213.368 180.199 212.995 180.199 212.536Z" fill="#ECECEC" />
                <path d="M183.134 197.936L181.787 209.22H180.284L178.937 197.936C178.787 196.677 179.769 195.57 181.037 195.57C182.302 195.57 183.284 196.677 183.134 197.936ZM180.199 212.536V212.499C180.199 212.037 180.573 211.663 181.034 211.663C181.496 211.663 181.869 212.038 181.869 212.499V212.536C181.869 213 181.491 213.375 181.028 213.372C180.569 213.368 180.199 212.995 180.199 212.536Z" stroke="#060606" strokeWidth="0.793646" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M218.096 207.721C211.379 211.97 204.203 215.605 197.2 219.246C194.435 220.684 191.47 222.061 188.268 221.678C186.808 221.503 185.409 220.955 184.147 220.2C183.804 219.994 183.462 219.766 183.215 219.449C182.744 218.843 182.694 218.019 182.709 217.25C182.78 213.807 185.222 211.875 187.726 209.874C196.111 203.178 204.495 196.479 212.88 189.781C214.095 188.811 215.353 187.818 216.85 187.403C218.855 186.847 220.98 187.405 222.985 187.959C223.611 187.327 223.791 187.046 224.417 186.413C225.909 184.908 227.479 183.353 229.485 182.669C232.951 181.487 236.675 183.175 239.946 184.824C241.583 182.942 244.279 181.951 246.724 182.44C249.169 182.928 251.321 184.412 253.07 186.19C255.755 185.267 258.313 185.492 260.741 186.964C263.168 188.437 265.097 190.588 266.958 192.734C280.508 208.346 292.258 224.976 302.432 242.907L326.456 279.494L281.891 304.038L251.838 263.483C236.436 254.133 227.015 231.487 218.096 207.721Z" fill="#F5F5F5" />
                <path d="M230.347 236.852C225.877 227.999 221.939 217.964 218.096 207.721C211.379 211.97 204.203 215.605 197.2 219.246C194.435 220.684 191.47 222.061 188.268 221.678C186.808 221.503 185.409 220.955 184.147 220.2C183.804 219.994 183.462 219.766 183.215 219.449C182.744 218.843 182.694 218.019 182.709 217.25C182.78 213.807 185.222 211.875 187.726 209.874C196.111 203.178 204.495 196.479 212.88 189.781C214.095 188.811 215.353 187.818 216.85 187.403C218.855 186.847 220.98 187.405 222.985 187.959C223.611 187.327 223.791 187.046 224.417 186.413C225.909 184.908 227.479 183.353 229.485 182.669C232.951 181.487 236.675 183.175 239.946 184.824C241.583 182.942 244.279 181.951 246.724 182.44C249.169 182.928 251.321 184.412 253.07 186.19C255.755 185.267 258.313 185.492 260.741 186.964C263.168 188.437 265.097 190.588 266.958 192.734C280.508 208.346 292.258 224.976 302.432 242.907L326.456 279.494L281.891 304.038L263.175 278.781" stroke="#060606" strokeWidth="0.793646" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M222.986 187.961L232.565 193.573" stroke="#060606" strokeWidth="0.793646" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M239.949 184.828L247.452 190.769" stroke="#060606" strokeWidth="0.793646" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M253.072 186.195L258.728 191.41" stroke="#060606" strokeWidth="0.793646" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M247.008 34.0551V136.227C247.008 139.493 245.4 142.141 243.417 142.141H140.077C138.095 142.141 136.486 139.493 136.486 136.227V34.0551C136.486 30.7887 138.095 28.1406 140.077 28.1406H243.417C245.4 28.1406 247.008 30.7887 247.008 34.0551Z" fill="#F4FBFF" stroke="#060606" strokeWidth="0.793646" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M175.674 110.992H169.152L207.823 65.8281H214.345L175.674 110.992Z" fill="#212121" />
                <path d="M207.823 110.992H214.345L175.674 65.8281H169.152L207.823 110.992Z" fill="#212121" />
                <path d="M247.009 34.0551V45.1034H136.488V34.0551C136.488 30.7887 138.096 28.1406 140.079 28.1406H243.419C245.4 28.1406 247.009 30.7887 247.009 34.0551Z" fill="#F4FBFF" stroke="#060606" strokeWidth="0.793646" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M235.586 39.6013C236.88 39.6013 237.928 38.5415 237.928 37.2342C237.928 35.9269 236.88 34.8672 235.586 34.8672C234.293 34.8672 233.244 35.9269 233.244 37.2342C233.244 38.5415 234.293 39.6013 235.586 39.6013Z" fill="#212121" />
                <path d="M226.475 39.6013C227.768 39.6013 228.817 38.5415 228.817 37.2342C228.817 35.9269 227.768 34.8672 226.475 34.8672C225.181 34.8672 224.133 35.9269 224.133 37.2342C224.133 38.5415 225.181 39.6013 226.475 39.6013Z" fill="#212121" />
                <path d="M216.739 39.6013C218.032 39.6013 219.081 38.5415 219.081 37.2342C219.081 35.9269 218.032 34.8672 216.739 34.8672C215.445 34.8672 214.396 35.9269 214.396 37.2342C214.396 38.5415 215.445 39.6013 216.739 39.6013Z" fill="#212121" />
                <path d="M191.323 52.3594C180.64 57.1871 169.641 59.0795 158.35 58.2348V98.9867C158.35 117.197 191.32 131.958 191.323 131.96C191.326 131.958 224.296 117.197 224.296 98.9867V58.2348C212.145 59.117 201.019 57.4916 191.323 52.3594Z" stroke="#212121" strokeWidth="0.771006" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M224.297 81.5609L219.081 75.0234L217.482 86.7899L212.279 85.1411" stroke="#212121" strokeWidth="0.771006" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M159.439 104.801L163.166 97.9844L167.471 101.83L172.892 93.6797" stroke="#212121" strokeWidth="0.771006" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M202.791 125.691L196.811 123.5L201.436 117.85L189.316 115.391" stroke="#212121" strokeWidth="0.771006" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M358.241 105.144V207.797C358.241 211.079 355.429 213.738 351.963 213.738H204.249C200.783 213.738 197.971 211.077 197.971 207.797V105.144C197.971 101.862 200.783 99.2031 204.249 99.2031H351.963C355.429 99.2031 358.241 101.862 358.241 105.144Z" fill="#F4FBFF" stroke="#060606" strokeWidth="0.793646" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M358.241 105.144V116.246H197.971V105.144C197.971 101.862 200.783 99.2031 204.249 99.2031H351.963C355.429 99.2031 358.241 101.862 358.241 105.144Z" fill="#F4FBFF" stroke="#060606" strokeWidth="0.793646" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M345.551 110.43C347.03 110.43 348.229 109.218 348.229 107.723C348.229 106.228 347.03 105.016 345.551 105.016C344.072 105.016 342.873 106.228 342.873 107.723C342.873 109.218 344.072 110.43 345.551 110.43Z" fill="#212121" />
                <path d="M335.133 110.43C336.612 110.43 337.811 109.218 337.811 107.723C337.811 106.228 336.612 105.016 335.133 105.016C333.654 105.016 332.455 106.228 332.455 107.723C332.455 109.218 333.654 110.43 335.133 110.43Z" fill="#212121" />
                <path d="M295.89 190.5H271.634C267.259 190.5 263.713 186.954 263.713 182.579V156.225H303.809V182.579C303.811 186.954 300.265 190.5 295.89 190.5ZM263.714 156.225V143.416C263.714 137.375 268.612 132.477 274.653 132.477H292.872C298.912 132.477 303.811 137.375 303.811 143.416V156.225" fill="#F4FBFF" />
                <path d="M263.714 156.225V143.416C263.714 137.375 268.612 132.477 274.653 132.477H292.872C298.912 132.477 303.811 137.375 303.811 143.416V156.225M295.89 190.5H271.634C267.259 190.5 263.713 186.954 263.713 182.579V156.225H303.809V182.579C303.811 186.954 300.265 190.5 295.89 190.5Z" stroke="#060606" strokeWidth="0.793646" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M323.998 110.43C325.477 110.43 326.676 109.218 326.676 107.723C326.676 106.228 325.477 105.016 323.998 105.016C322.519 105.016 321.32 106.228 321.32 107.723C321.32 109.218 322.519 110.43 323.998 110.43Z" fill="#212121" />
                <path d="M287.955 169.747C287.955 167.431 286.078 165.555 283.763 165.555C281.447 165.555 279.57 167.431 279.57 169.747C279.57 170.979 280.101 172.086 280.947 172.853V181.173H286.578V172.853C287.422 172.086 287.955 170.979 287.955 169.747Z" fill="#212121" />
                <path d="M83.3436 119.32C105.554 119.32 123.558 101.315 123.558 79.1053C123.558 56.8954 105.554 38.8906 83.3436 38.8906C61.1337 38.8906 43.1289 56.8954 43.1289 79.1053C43.1289 101.315 61.1337 119.32 83.3436 119.32Z" fill="#F5F5F5" />
                <path d="M83.3436 119.32C105.554 119.32 123.558 101.315 123.558 79.1053C123.558 56.8954 105.554 38.8906 83.3436 38.8906C61.1337 38.8906 43.1289 56.8954 43.1289 79.1053C43.1289 101.315 61.1337 119.32 83.3436 119.32Z" stroke="#060606" strokeWidth="0.793646" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M83.3443 74.3682C87.7369 74.3682 91.2979 70.8072 91.2979 66.4146C91.2979 62.0219 87.7369 58.4609 83.3443 58.4609C78.9516 58.4609 75.3906 62.0219 75.3906 66.4146C75.3906 70.8072 78.9516 74.3682 83.3443 74.3682Z" fill="#212121" />
                <path d="M83.3436 76.6797C74.2376 76.6797 66.8555 84.0619 66.8555 93.1678H99.8301C99.8301 84.0619 92.4495 76.6797 83.3436 76.6797Z" fill="#212121" />
              </svg>
            </div>
          </div>

        </div>
        <div className="col-lg-6 d-flex justify-content-center align-items-center">
          <div className="login-sec">
            <div className="w-100">
              <CustomInput
                label={"Current Password"}
                onChange={(e) =>
                  setLoginData((prev) => ({
                    ...prev,
                    currentPassword: e.target.value,
                  }))
                }
                placeholder="∗∗∗∗∗"
                type={showPassword ? "text" : "password"}
                setShowPassword={() => setShowPassword(!showPassword)}
                isIcon
                handleKeyPress={handleKeyPress}
              />
              <CustomInput
                label={"New password"}
                onChange={(e) =>
                  setLoginData((prev) => ({
                    ...prev,
                    password: e.target.value,
                  }))
                }
                placeholder="∗∗∗∗∗"
                type={showPassword1 ? "text" : "password"}
                setShowPassword={() => setShowPassword1(!showPassword1)}
                isIcon
                handleKeyPress={handleKeyPress}
              />
              <CustomInput
                label={"Confirm Password"}
                onChange={(e) =>
                  setLoginData((prev) => ({
                    ...prev,
                    newPassword: e.target.value,
                  }))
                }
                placeholder="∗∗∗∗∗"
                type={showPassword1 ? "text" : "password"}
                setShowPassword={() => setShowPassword1(!showPassword1)}
                isIcon
                handleKeyPress={handleKeyPress}
              />
            </div>
            <CustomButton
              title={"Change Password"}
              handleFunction={handleSignup}
              loader={loader}
            />
          </div>
        </div>
      </div>
      {contextHolder}
      <ToastContainer />
    </>
  );
}
