export const checkFormValidation = ({ editDetails, showErrorMessage, fullName, updateNumber }) => {

    let missingFields = [];

    if (editDetails.organization === '') {
        missingFields.push('Organization');
    }
    if (editDetails.email === '') {
        missingFields.push('Email ID');
    }
    if (editDetails.phone_number.number === '' || isNaN(editDetails.phone_number.number) || isNaN(updateNumber?.number)) {
        missingFields.push('Phone Number');
    }

    if (fullName.fname === '') {
        missingFields.push('First name');
    }
    if (fullName.lname === '') {
        missingFields.push('Last name')
    }

    if (missingFields.length > 0) {
        let message = `Please fill in the following fields: ${missingFields.join(', ')}`
        showErrorMessage(message);
        return true;
    }
    else {
        return false;
    }
}


