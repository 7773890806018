import React from 'react'
import './index.css'
import CheckMark from '../../../assets/signUp/Line-System-Check.svg'
export default function CustomCheckBox(props) {
    const { label,id, className, name } = props
    return (
        <label for="check" className="checkLabel">
            <div class="checkbox_wrapper">
                <input type="checkbox" name={name} className={className} id={id} {...props} />
                <span className="checkMark">
                    <img src={CheckMark} alt="" />
                </span>
            </div>
            {label}
        </label>
    )
}
