import React, { useState } from "react";
import svg from "../../assets/signUp/search.svg";
import "./index.css";
import CustomSelect from "../core/CustomSelect";
import { BiSearch } from "react-icons/bi";

const SearchSortFilter = ({
  onSearch,
  setSearch,
  search,
  setSortOption,
  onSort,
  onClickFilter,
  filterOptions,
  sortOptions,
  width,
}) => {

    // const [sortOptions, setSortOptions] = useState([
    //   "Newest to oldest",
    //   "oldest to Newest",
    //   "A-Z Ascending order",
    //   "Z-A Descending order",
    // ]);
 
  return (
    <div className="builder-input">
       <div className="search-bar" style={{ width, }}>
        <BiSearch className="icon-search" size="1.2rem" />
        <input
          type="text"
          placeholder="Search"
          name="search"
          id="sea"
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={onSearch}
        />
      </div>
      <div className="filter-sort-div">
        <div className="d-flex sort-bar">
          <CustomSelect
            title={"Sort"}
            className={"sortBy"}
            defaultValue={"Sort by"}
            options={sortOptions}
            titleClass={"fil-title"}
            onChange={(e)=>onSort(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default SearchSortFilter;
