import React, { useEffect, useState, } from "react";
import axios from "axios";
import { useLocation,useNavigate } from 'react-router-dom';
import {loginWorkosCall} from '../../api/users';
import { setAccessToken, setEmail } from "../../api/common";
import { notification } from 'antd'
export default function SocialPageCall() {
 
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
useEffect(()=>{

(async()=>{
  try {
    const code =  params.get('code');

    if(!code){
      return  navigate("/user/login");
    }
 
   let userData =  await loginWorkosCall({code});
   setAccessToken(userData.token);
   setEmail(userData.email);
   return navigate("/home/explore");
   
  } catch (error) {
    return  navigate("/user/login");
  }

})();
  
  //   localStorage.setItem('accessToken',params.get('token'));
  //   localStorage.setItem('email',params.get('email'));
  //  return navigate("/home/explore");
},[]);    

  return <>
  </>
}
