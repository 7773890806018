import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./index.css";

function TelSelect(props) {
    const {htmlFor,label} = props; 
    return (
        <div className="formGroup">
            <label htmlFor={htmlFor} className="field-title-login">{label}</label>
            <PhoneInput 
                {...props}  
                disableSearchIcon="true"  
                countryCodeEditable={false}  
            />
        </div>  
    );
}

export default TelSelect;
