import React from 'react'
import "./style.css";
import Logo from "../../../assets/idflow-logo.svg";


export default function IDFlowHeader() {
  return (
    <div className='id_flow_header'>
        <img src={Logo} alt="" />
    </div>
  )
}
