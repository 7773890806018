import { commonRequestV1 } from "./common";
const path = (user) => "/secrets/" + user;

// export const getAllSecrets(=>{
//
// })


export const getAllSecrets = (filter) => {
  return commonRequestV1("get", path("?limit=100&filter=" + filter), {});
};

// export const getAllSecrets = (
//   limit = 100,
//   skip = 0,
//   search = "",
//   sortOrder = "desc",
//   sortBy = "created_at",
//   filter = ""
// ) => {
//   return commonRequestV1(
//     "get",
//     path(
//       `?limit=${limit}&skip=${skip}&search=${search}&filter=${filter}&sortOrder=${sortOrder}&sortBy=${sortBy}`
//     )
//   );
// };





export const getSecretsByID = (id) => {
  return commonRequestV1("get", path(id));
};

export const getSecrets = () => {
  return commonRequestV1("get", "v1/secrets");
};

export const createSecrets = (user, onSuccess) => {
  return commonRequestV1("post", path(""), user, onSuccess);
};

export const deleteSecrets = (id, onSuccess) => {
  return commonRequestV1("delete", path(id), {}, onSuccess);
};

export const updateSecrets = (id, body) => {
  return commonRequestV1("put", path(id), body);
};
