import { notification } from 'antd';
import { useFormik } from 'formik';
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from 'yup';
import { setAccessToken, setEmail } from "../../api/common";
import { checkSSO, login, loginEmail, loginWithSSO, sendEmail, socialLogin } from "../../api/users";
import Google from "../../assets/signUp/google-icon.svg";
import Outlook from "../../assets/signUp/outlook.svg";
import CusBtn from '../../components/FormComponents/Button/Buttons';
import '../../styles/global.css';
import CustomButton from "../core/Button";
import CustomFooter from "../core/Footer";
import CustomHeader from "../core/Header";
import IDFlowHeader from "../core/ID-Flow-Header/IDFlowHeader";
import CustomInput from "../core/InputField";
import NewUserRegistration from './NewUserRegistration';
import "./login.css";
import OneTimePassword from './loginOtpVerification';


const thisTenant = () => window.location.hostname.split('.').at(0);

export default function LoginScreen() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
//Has 
  const [showSSO,setShowSSO]= useState(false);
  const [showChangeEmail,setShowChangeEmail]=useState(false);
  const [newUserRegState, setnewUserRegState] = useState(1);
  const [loader, setLoader] = useState(false);
  // const [loginData, setLoginData] = useState({
  //   email: "",
  //   password: "",
  // });
  const [userDetails, setUserDetails] = useState({});
  const [api, contextHolder] = notification.useNotification();


  useEffect(() =>{
    checkSSO({}).then((res)=>{
  
      if(res.sso){
        setShowSSO(true);
      }
    }).catch((err)=>{
      showErrorMessage(err.message)
    })
  }, [])
  const showErrorMessage = (err) => {
    api.error({
      description: err ? err : "Some thing went wrong",
      placement: "bottomRight",
      duration: 2,
      className: 'toast-error-message'
    })
  };
  const showSuccessMessage = (val) => {
    api.success({
      description: val ? val : "User login successful!",
      placement: "bottomRight",
      duration: 2,
      className: 'toast-success-message'
    })
  };


  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      formik.handleSubmit();
    }
  };


 const handleSocialLogin = async (user)=>{
  try {
    let {url} = await socialLogin(user);  
    window.location.replace(url);
  } catch (error) {
    showErrorMessage();
  }

 }

 const handleSSO=()=>{
  setLoader(true);
  loginWithSSO({}).then((res)=>{
  if(res){
    showSuccessMessage("");
    setLoader(false);
    
  }
    }).catch((err)=>{
      setLoader(false);
      if(err.response.status==301){
        setTimeout(() => {
          window.location.href=err?.response?.data?.url;
        }, 1000);
      } else {
        showErrorMessage(err?.response?.message)
      }
    })
    setLoader(true);
 }


 const validation = yup.object().shape({
  email: yup.string().email().required("Please Enter your Email").matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,}$/, "Please Check your Email"),
 })
 const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
 const passValidation = yup.object().shape({
  password: yup.string().matches(passwordRules, { message: "password should contain at least 1 lower case letter, 1 upper case letter, 1 digit and 1 symbol" }).required("please check password")

 })

 const formik = useFormik({
  initialValues: {
    email: "",
    password: ""
  },
  validationSchema: !showChangeEmail ? validation : passValidation,
  onSubmit: values => {
    if (values.email && values.password == "") {
      setLoader(true);
      loginEmail(values)
        .then((res) => {
          setLoader(false);
          if (res.password) setShowChangeEmail(true);
          if (!res.password && res.sso) setnewUserRegState(0)
        })
        .catch((err) => {
          setShowChangeEmail(false);
          setLoader(false);
          if(err?.response?.status==404){
            setnewUserRegState(2)
            // createAccount();
            showErrorMessage("User does not exist");
          }  else if(err?.response?.status==401){
            setLoader(false);
            let currentTenant=window.location.host.split(".");
            currentTenant[0]=err?.response?.data?.tenantId;
            let restructuredUrl=`${window.location.protocol}//${currentTenant.join(".")}${window.location.pathname}${window.location.search}`
            window.location.href=restructuredUrl;
          }  else {
            showErrorMessage(err?.response?.message)
          }
        });
    }
    if(values.email && values.password) {
      setLoader(true);
      login(values).then((res)=>{
      if(res){
            showSuccessMessage("");
            // console.log(res)
            setAccessToken(res.token);
            setEmail(values.email);
            setUserDetails(res.user);
            setLoader(false);
             setTimeout(() => {

              if (thisTenant() === res.tenant){
                navigate("/home/explore");
              } else{
                // Logined from different tenant
                let host = window.location.hostname.split('.');
                window.location.replace(`https://${res.tenant}.${host.at(1)}.${host.at(2)}/home/callback?token=${res.token}&email=${res.email}`)
              }
              
            }, 2000);
}
      }).catch((error)=>{
        setLoader(false);
        showErrorMessage(error?.response?.message)
      })
    }

  },
});

const createAccount = () => { 
  setLoader(true)
  sendEmail(formik.values.email)
    .then((res) => {
      showSuccessMessage(res.message)
      setLoader(false);
      setnewUserRegState(1) // ! uncomment this to show new login opt screen
    })
    .catch((err) => {
      setLoader(false);
      showErrorMessage(err.response.message);
    });
};

  return (
    <div className="main-login">
      <IDFlowHeader />
      <div className="body-login">
        <div className="login-sec">
          {
            (newUserRegState == 1 || newUserRegState == 0) ? (
              <>
              <div>
                <CustomHeader
                  title={ showChangeEmail === false ? "Welcome to ID Flow" : `Welcome Back, ${formik.values.email.split('@')[0]}`}
                />
                <p className='m-0 login-para'>
                  {!showChangeEmail ? "In just a few steps, you can start building custom workflows!" : <>
                  Please enter your password to login from {formik.values.email}
                  <span className='edit-btn ms-2' onClick={() =>{
                     setShowChangeEmail(false)
                     formik.setFieldValue('password', "")
                     }}>Edit</span>
                  </>}
                </p>
                <div className="form_group_input" style={{ margin: "32px 0px"}}>
                  <CustomInput
                    label={"Email ID"}
                    id={"email"}
                    onChange={formik.handleChange}
                    value={formik.values.email}                
                    placeholder={"Enter your email"}
                    handleKeyPress={handleKeyPress}
                    disabled={showChangeEmail}
                  />
                  {formik.errors.email && <p className='formik_error'>{formik.errors.email}</p>}
                </div>
            {
            showChangeEmail && <>
            <div className="form_group_input">
              <CustomInput
                id={"password"}
                label={"Password"}
                onChange={formik.handleChange}
                value={formik.values.password}
                placeholder="Enter your password"
                type={showPassword ? "text" : "password"}
                setShowPassword={() => setShowPassword(!showPassword)}
                handleKeyPress={handleKeyPress}
                isIcon
              />
              {formik.errors.password && <p className='formik_error'>{formik.errors.password}</p>}
              {showChangeEmail && (
                <span className='forgot-btn mt-1' onClick={() => navigate("/user/forget")}>Forgot Password?</span>
              )}
            </div>

           </>
            }
            {/* {
              showChangeEmail && <button onClick={()=>{
              
                formik.resetForm({
                  values: {email: "", password: ""}
                });
                // setShowSSO(false)
                setShowChangeEmail(false);
              }} className="changeEmail">Change Email</button>
            } */}
          </div>
          <div>
            {showChangeEmail===true ? ( 
              <CustomButton handleFunction={formik.handleSubmit} title={"Login"} loader={false} />
            ) : (
              !newUserRegState == 0 ? <CustomButton handleFunction={formik.handleSubmit} title={"Continue"} loader={false} /> : null
            )}
          </div>
              </>
            ) : (newUserRegState == 2) ? (
              <NewUserRegistration
                setnewUserRegState={setnewUserRegState}
                createAccount={createAccount}
                email={formik.values.email}
                />
                ) : (newUserRegState == 3) ? (
                  <OneTimePassword 
                    email={formik.values.email}
                    setnewUserRegState={setnewUserRegState}
                  />
            ) : null
          }


            <div>
            {showSSO && <div className='login-btn-w-100'>
              <CusBtn onClick={handleSSO} value={"Single Sign On"} loader={false} variant="outlined" />
            </div> }
            <div className="linediv mt-2">
              <p className="line" />
              <p className="linetext">or Sign In with</p>
              <p className="line" />
            </div>
            <div className="btn-div">
              <button onClick={async()=> await handleSocialLogin({auth_type: "GoogleOAuth"})}  className="icon-btn">
                <img src={Google} alt="icon" />
              </button>
              
              <button onClick={async ()=>{await handleSocialLogin({auth_type: "MicrosoftOAuth"})}} className="icon-btn">
                <img src={Outlook} alt="icon" />
              </button>
              
              
            </div>
          </div>
        </div>
      </div>
      {contextHolder}
      <CustomFooter />

    </div>
  );
}
